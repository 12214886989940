import React, { useEffect, useState } from "react";
import Header from "../layout/header";
import PasswordInput from "../components/inputs/PasswordInput";
import TextInput from "../components/inputs/TextInput";
import Button from "../components/buttons/DefaultButton";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { regexEmail } from "../utils/helpers";
import { ERROR } from "../utils/contants";
import { signUp } from "../services/auth-services";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Swal from "sweetalert2";
import { useCookies } from "react-cookie";
import { googleSvg } from "../components/svg/svg";

const DEFAULT_FIELDS = {
  email: "",
  password1: "",
  password2: "",
};
const SignUp = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [cookies, removeCookie] = useCookies(["cookieName"]);
  const [credentials, setCredentials] = useState(DEFAULT_FIELDS);

  // Access the value of the cookie
  const refreshToken = cookies.refresh;
  const accessToken = cookies.access;

  useEffect(() => {
    if (refreshToken !== "undefined") {
      let data = JSON.stringify({ refresh: refreshToken, access: accessToken });
      localStorage.setItem("user", data);
      removeCookie("refresh");
      removeCookie("access");
      window.location.href = "/";
    }
    // eslint-disable-next-line
  }, []);

  function checkValidations() {
    if (!regexEmail(credentials.email)) {
      toast.warn(ERROR.EMAIL_VALIDATION);
      setLoading(false);
      return false;
    } else if (credentials.password1 === "") {
      toast.warn(ERROR.EMPTY_PASSWORD);
      setLoading(false);
      return false;
    } else if (credentials.password1.length < 8) {
      toast.warn(ERROR.PASSWORD_LENGTH);
      setLoading(false);
      return false;
    } else if (credentials.password2 === "") {
      toast.warn(ERROR.CONFORM_EMPTY);
      setLoading(false);
      return false;
    } else if (credentials.password1 !== credentials.password2) {
      toast.warn(ERROR.SAME_ERROR);
      setLoading(false);
      return false;
    }
    return true;
  }
  const handleSignup = () => {
    setLoading(true);
    if (checkValidations()) {
      signUp(credentials)
        .then((res) => {
          navigate("/signin");
          Swal.fire({
            title: "Account created",
            text: "Please verify your email!",
            icon: "success",
          });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            const errors = error.response.data;
            Object.keys(errors).forEach((key) => {
              errors[key].forEach((message) => {
                toast.error(`${message}`);
              });
            });
          } else {
            toast.error("Network error.");
          }
        });
    }
  };
  const onChangeFields = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };
  return (
    <div className="mt-20/">
      <Header />
      <div className="w-full mt-20 h-[70vh] flex flex-col justify-center items-center m-auto">
        <div className=" w-[400px] flex flex-col gap-4">
          <div className="text-2xl font-semibold leading-[35px] text-center">
            Create account
          </div>
          <TextInput
            name={"email"}
            onChange={(e) => onChangeFields(e)}
            placeholder={"Enter your email"}
          />
          <PasswordInput
            name={"password1"}
            onChange={(e) => onChangeFields(e)}
            placeholder={"Create password"}
          />
          <PasswordInput
            onChange={(e) => onChangeFields(e)}
            name="password2"
            placeholder={"Confirm password"}
          />
          <Button
            onClick={handleSignup}
            text={
              loading ? (
                <AiOutlineLoading3Quarters className="text-2xl animate-spin" />
              ) : (
                "Continue"
              )
            }
            customClass={"!w-full !mt-2 !py-2 !font-normal !text-[12px]"}
          />
          <div className="flex items-center gap-4">
            <hr className="w-full" />
            <span className="text-grayColor text-xs">or</span>
            <hr className="w-full" />
          </div>
          <a href={"/api/accounts/google_login/"}>
            <Button
              text={"Sign Up with Google "}
              customClass={
                "!w-full !mt-2 !py-2 !font-normal !text-[12px] !bg-transparent text-black border-grayColor border"
              }
            >
              {googleSvg}
            </Button>
          </a>
        </div>
        <div className="text-[#515961s text-xs mt-[180px] text-center fixed bottom-5 left-0 right-0">
          Have an account already?{" "}
          <span
            className="text-primary cursor-pointer"
            onClick={() => navigate("/signin")}
          >
            Sign in
          </span>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
