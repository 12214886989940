import React, { useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import Button from "../components/buttons/DefaultButton";
import TextInput from "../components/inputs/TextInput";
import { submit_feedback } from "../services/auth-services";
import { toast } from "react-toastify";

const Support = () => {
  const [data, setData] = useState({
    screenshot: "",
    name: "",
    email: "",
    message: "",
  });

  const onImageChange = (event) => {
    setData({ ...data, screenshot: event.target.files[0] });
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setData({ ...data, [name]: value });
  };

  const uploadImage = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    submit_feedback(user?.access, data)
      .then((response) => {
        toast.success("query submitted");
      })
      .catch((error) => {
      });
  };
  return (
    <DashboardLayout footer>
      <div className="py-5 md:py-28">
        <div className=" md:flex md:justify-between md:w-[70%] m-auto md:gap-12">
          <div className="w-32 h-14  relative md:pt-14 hidden md:block">
            <div className="md:h-12 md:w-12 relative bottom-[-108px] bg-secondary"></div>
            <div className="absolute bottom-[-196px] left-12 md:h-10 w-10 bg-primary"></div>
          </div>
          <div className=" md:w-[80%] ...">
            <h2 className="font-semibold text-4xl text-secondary m-auto w-full h-[72px] text-center pt-5 ">
              <span className="underline-offset-[16px] underline text-primary ">
                Contact
              </span>
              {" Us"}
            </h2>
            {/* <hr className="w-28 px-5 m-auto border-b-2 " /> */}
            <p className="font-[400] text-center pt-7 p-5">
              Just send us your questions or concerns and our staff member will
              give you the help you need. Please use this form to contact us and
              we will get back to you.
            </p>
          </div>
          <div className="w-32 h-14 relative  hidden md:block">
            <div className="h-12 w-12 relative top-[-50px] bg-primary"></div>
            <div className="absolute bottom-[18px] left-12 h-10 w-10   bg-secondary"></div>
          </div>
        </div>

        <div className="md:flex md:w-[72%] md:m-auto md:border-2  rounded-3xl md:mt-44 ">
          <div className="w-8/12 md:w-6/12 m-auto ">
            <img
              className="md:mt-0 md:px-8 md:ml-4 rounded-xl md:rounded-none "
              src="/images/contact.png"
              alt=""
            />
          </div>
          <div className="md:w-[85%] p-5 md:p-10">
            <h2 className="text-primary font-[600] text-[20px] text-center p-7">
              Get in Touch
            </h2>

            <div className="mb-6 P-7 ">
              <TextInput
                name={"name"}
                onChange={(e) => handleChange(e)}
                label={"Your Name"}
                placeholder="Enter your name...."
              />
            </div>
            <div className="mb-6 P-7 ">
              <TextInput
                label={"Your Email"}
                onChange={(e) => handleChange(e)}
                name={"email"}
                placeholder="Enter your email...."
              />
            </div>
            <div className="mb-6 P-7 ">
              <TextInput
                label={"Your Message"}
                textarea
                name={"message"}
                customClass={"h-28"}
                onChange={(e) => handleChange(e)}
                placeholder="Enter your message...."
              />
            </div>
            <input type="file" onChange={onImageChange} />
            <div className="flex justify-center ">
              <Button
                onClick={uploadImage}
                customClass="bg-primary  text-white font-bold text-xs w-28 h-10 py-2  rounded"
                text={"Send"}
              />
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Support;
