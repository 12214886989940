import { convertToDollars } from "../../utils/helpers";

const PlanCard = ({ item, selectedPlan, index, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`${
        selectedPlan === index && "border-primary bg-white shadow-inputShadow"
      } border border-solid cursor-pointer rounded-xl px-5 w-full md:w-56`}
    >
      <div className=" py-4">
        <div className="flex justify-center mt-2 mb-3">
          <button className="bg-slate-100 shadow-inputShadow px-3 py-1 text-primary text-xs font-semibold">
            {item?.product_name}
          </button>
        </div>
        <div className="flex justify-center items-center">
          <span className="text-2xl font-semibold">
            ${convertToDollars(item?.unit_amount)}
          </span>
        </div>
        <div className="mt-3 text-gray-500 text-xs">{item?.pageInfo}</div>
      </div>
    </div>
  );
};
export default PlanCard;
