import axios from "axios";
// live
const payment_url = `https://dashboard.trycareermap.com/api/payments`;
const auth_url = `https://dashboard.trycareermap.com/api/accounts`;
const apiUrl = `https://dashboard.trycareermap.com/api/core`;

// server

// const payment_url = `http://137.59.224.25:8083/api/payments`;
// const auth_url = `http://137.59.224.25:8083/api/accounts`;
// const apiUrl = `http://137.59.224.25:8083/api/core`;
//
// local

// const payment_url = `http://192.168.0.100:8000/api/payments`;
// const auth_url = `http://192.168.0.100:8000/api/accounts`;
// const apiUrl = `http://192.168.0.100:8000/api/core`;

export function changeProfile(token, data) {
    return axios({
        method: "PUT", headers: {
            Authorization: `Bearer ${token}`,
        }, url: `${auth_url}/user_profile/`, data,
    });
}
export function getProfile(token) {
    return axios({
        method: "GET", headers: {
            Authorization: `Bearer ${token}`,
        }, url: `${auth_url}/user_profile/`,
    });
}
export function submit_feedback(token, data) {
    return axios({
        method: "POST", headers: {
            "Content-Type": "multipart/form-data", Authorization: `Bearer ${token}`,
        }, url: `${auth_url}/user_feedback/`, data,
    });
}
export function getUserReports(token) {
    return axios({
        method: "GET", headers: {
            Authorization: `Bearer ${token}`,
        }, url: `${apiUrl}/reports_history/`,
    });
}
export function getUserCredits(token) {
    return axios({
        method: "GET", headers: {
            Authorization: `Bearer ${token}`,
        }, url: `${auth_url}/user_credits/`,
    });
}
export function getUserTotalCredits(token) {
    return axios({
        method: "GET", headers: {
            Authorization: `Bearer ${token}`,
        }, url: `${auth_url}/user_credits_total/`,
    });
}
export function getPrices(token) {
    return axios({
        method: "GET", headers: {
            Authorization: `Bearer ${token}`,
        }, url: `${payment_url}/prices/`,
    });
}
export function paymentSession(data, token) {
    return axios({
        headers: {
            Authorization: `Bearer ${token}`,
        }, method: "POST", url: `${payment_url}/create_payment_session/`, data,
    });
}

export function googleLogIn() {
    return axios({
        method: "GET", url: `${auth_url}/google_login/`,
    });
}

export function LogIn(data) {
    return axios({
        method: "POST", url: `${auth_url}/login/`, data,
    });
}

export function RecordVisitor() {
    return axios({
        method: "GET", url: `${auth_url}/visitor/`,
    });
}

export function signUp(data) {
    return axios({
        method: "POST", url: `${auth_url}/signup/`, data,
    });
}

export function resetPassword(data) {
    return axios({
        method: "POST", url: `${auth_url}/password_reset/`, data,
    });
}

export function confirmPassword(data) {
    return axios({
        method: "POST", url: `${auth_url}/password_reset/confirm/${data?.reset_key}/`, data,
    });
}

export function getAllCountries(token) {
    return axios({
        method: "GET", headers: {
            Authorization: `Bearer ${token}`,
        }, url: `${apiUrl}/countries`,
    });
}

export function getRelatedStates(id, token) {
    return axios({
        method: "GET", headers: {
            Authorization: `Bearer ${token}`,
        }, url: `${apiUrl}/countries/${id}/states/`,
    });
}

export function postDetails(data, token) {
    return axios({
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
        }, url: `${apiUrl}/user-data/`,
        data,
    });
}

export function getUserData(UUID, token) {
    return axios({
        method: "GET", headers: {
            Authorization: `Bearer ${token}`,
        }, url: `${apiUrl}/user-data/${UUID}`,
    });
}

export function getSampleUserData(UUID) {
    return axios({
        method: "GET", url: `${apiUrl}/user-data/${UUID}`,
    });
}

export function getDataByPlace(txt, countryName, token) {
    return axios({
        method: "GET", headers: {
            Authorization: `Bearer ${token}`,
        }, url: `${apiUrl}/places/${txt}/${countryName?.name ? `${countryName?.name}/` : ""}`,
    });
}

export function getDataByDegree(txt) {
    return axios({
        method: "GET", url: `${apiUrl}/degree/${txt}/`,
    });
}
