import React from "react";
import DashboardLayout from "../layout/DashboardLayout";

const Cancelled = () => {
  return (
    <DashboardLayout footer>
      <div className="flex flex-col justify-center h-[70vh] items-center">
        <svg
          className=""
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 470 412"
          width="256"
          height="256"
        >
          <path
            fill="#4F46E5"
            d="M347.8 64.5H80.4c-27.1 0-49.3 22.3-49.3 49.3v157.6c0 25.5 22.3 47.7 49.3 47.7h269c27.1 0 49.3-22.3 49.3-49.3v-156c-1.6-27-23.9-49.3-50.9-49.3z"
            class="color50bae8 svgShape"
          />
          <path
            fill="#f1f1f3"
            d="M198.2 191.8H86.8c-4.8 0-8-3.2-8-8s3.2-8 8-8h111.4c4.8 0 8 3.2 8 8-.1 4.9-3.3 8-8 8zm-47.8 79.6H86.8c-4.8 0-8-3.2-8-8s3.2-8 8-8h63.7c4.8 0 8 3.2 8 8s-3.3 8-8.1 8zm191-79.6h-47.7c-4.8 0-8-3.2-8-8s3.2-8 8-8h47.7c4.8 0 8 3.2 8 8 0 4.9-3.2 8-8 8z"
            class="colorf1f1f3 svgShape"
          />
          <path
            fill="#6e6b6c"
            d="M31.1 125h367.6v20.7H31.1z"
            class="color6e6b6c svgShape"
          />
          <circle
            cx="356.2"
            cy="312.2"
            r="87"
            fill="#f16169"
            class="colorf16169 svgShape"
          />
          <path
            fill="none"
            stroke="#f1f1f3"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="14"
            d="m318.9 353.8 74.6-74.6M393.5 353.8l-74.6-74.6"
            class="colorStrokef1f1f3 svgStroke"
          />
        </svg>
        <div className="text-center text-3xl text-secondary mt-5 font-medium">
          Your payment has been cancelled
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Cancelled;
