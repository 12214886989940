import React from "react";
import Button from "../buttons/DefaultButton";
import { useNavigate } from "react-router-dom";
const FromFooter = ({
  handleBack,
  isProgress,
  totalSteps,
  handleNext,
  handleSubmit,
}) => {
  const navigate = useNavigate();
  return (
    <div className="flex gap-3 justify-between mt-10">
      <div className="">
        <Button
          onClick={() => navigate("/")}
          text="Back to Dashboard"
          customClass={
            " !text-base flex-row-reverse !bg-transparent !text-primary !px-0 !text-xs !py-1 disabled:cursor-not-allowed"
          }
        />
      </div>
      <div className="flex gap-3 justify-end mt-2">
        {isProgress !== 1 && (
          <Button
            disabled={isProgress === 1}
            onClick={handleBack}
            text={"Previous"}
            customClass={
              " !bg-transparent border !text-primary !text-sm md:text-base !border-[#D0D5DD] !py-1.5 disabled:cursor-not-allowed"
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="10"
              viewBox="0 0 16 10"
              fill="none"
            >
              <path
                d="M1.33316 4.99976L14.6665 4.99976"
                stroke="#455571"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.49967 9.16659C5.49967 9.16659 1.33305 6.09788 1.33304 4.99989C1.33303 3.90189 5.49971 0.833252 5.49971 0.833252"
                stroke="#455571"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Button>
        )}
        <Button
          onClick={isProgress === totalSteps ? handleSubmit : handleNext}
          text={isProgress === totalSteps ? "Finish" : "Next"}
          customClass={
            " !text-sm md:text-base flex-row-reverse !py-1 disabled:cursor-not-allowed"
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="10"
            viewBox="0 0 15 10"
            fill="none"
          >
            <path
              d="M14.1668 4.99976L0.833435 4.99976"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.0002 9.16659C10.0002 9.16659 14.1668 6.09788 14.1669 4.99989C14.1669 3.90189 10.0002 0.833252 10.0002 0.833252"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
      </div>
    </div>
  );
};

export default FromFooter;
