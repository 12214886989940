import React, {useEffect, useState} from "react";
import Header from "../layout/header";
import PasswordInput from "../components/inputs/PasswordInput";
import TextInput from "../components/inputs/TextInput";
import Button from "../components/buttons/DefaultButton";
import {useNavigate} from "react-router-dom";
import {ERROR} from "../utils/contants";
import {regexEmail} from "../utils/helpers";
import {toast} from "react-toastify";
import {LogIn, RecordVisitor} from "../services/auth-services";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {useCookies} from "react-cookie";
import {googleSvg} from "../components/svg/svg";

const DEFAULT_FIELDS = {
    email: "",
    password: "",
};

const logPageLoad = () => {
    RecordVisitor()
};
const SignIn = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [credentials, setCredentials] = useState(DEFAULT_FIELDS);
    const [cookies, removeCookie] = useCookies(["cookieName"]);
    // Access the value of the cookie
    const refreshToken = cookies.refresh;
    const accessToken = cookies.access;

    useEffect(() => {
        logPageLoad(); // Call this function to log page load
        if (refreshToken !== "undefined") {
            let data = JSON.stringify({refresh: refreshToken, access: accessToken});
            localStorage.setItem("user", data);
            removeCookie("refresh");
            removeCookie("access");
            window.location.href = "/";
        }
        // eslint-disable-next-line
    }, []);

    function checkValidations() {
        if (!regexEmail(credentials.email)) {
            toast.warn(ERROR.EMAIL_VALIDATION);
            setLoading(false);
            return false;
        } else if (credentials.password === "") {
            toast.warn(ERROR.EMPTY_PASSWORD);
            setLoading(false);
            return false;
        } else if (credentials.password.length < 8) {
            toast.warn(ERROR.PASSWORD_LENGTH);
            setLoading(false);
            return false;
        }
        return true;
    }

    const handleSignIn = () => {
        setLoading(true);
        if (checkValidations()) {
            LogIn(credentials)
                .then((res) => {
                    let data = JSON.stringify(res.data);
                    localStorage.setItem("user", data);
                    toast.success("Logged In");
                    setLoading(false);
                    window.location.href = "/";
                })
                .catch((error) => {
                    setLoading(false);
                    if (error.response) {
                        const errors = error.response.data;
                        console.log('errors: ', errors);
                        // Object.keys(errors).forEach((key) => {
                        //   if (Array.isArray(errors[key])) {
                        //     errors[key].forEach((message) => {
                        //       toast.error(`${message}`);
                        //     });
                        //   } else {
                        toast.error(errors.error);
                    } else {
                        toast.error("Network error.");
                    }
                });
        }
    };
    const onChangeFields = (e) => {
        const {name, value} = e.target;
        setCredentials({...credentials, [name]: value});
    };

    return (
        <div className="mt-20/">
            <Header/>
            <div className="w-full mt-20 h-[70vh] flex flex-col justify-center items-center m-auto">
                <div className="w-full sm:px-20 md:px-0 md:w-2/3 lg:w-[400px] px-5 flex flex-col gap-4">
                    <div className="text-2xl font-semibold leading-[35px] text-center">
                        Sign In
                    </div>
                    <TextInput
                        name="email"
                        onChange={(e) => onChangeFields(e)}
                        placeholder={"Enter your email"}
                    />
                    <PasswordInput
                        name="password"
                        onChange={(e) => onChangeFields(e)}
                        placeholder={"Enter password"}
                    />
                    <div
                        onClick={() => navigate("/reset-password")}
                        className="text-primary cursor-pointer text-sm text-right"
                    >
                        Forgot password?
                    </div>
                    <Button
                        disabled={loading}
                        text={
                            loading ? (
                                <AiOutlineLoading3Quarters className="text-2xl animate-spin"/>
                            ) : (
                                "Continue"
                            )
                        }
                        onClick={handleSignIn}
                        customClass={"!w-full !py-2 !font-normal !text-[12px]"}
                    />
                    <div className="flex items-center gap-4">
                        <hr className="w-full"/>
                        <span className="text-grayColor text-xs">or</span>
                        <hr className="w-full"/>
                    </div>
                    <a href={"/api/accounts/google_login/"}>
                        <Button
                            text={"Sign in with google"}
                            customClass={
                                "!w-full !mt-2 !py-2 !font-normal flex-row-revers !text-[12px] !bg-transparent text-blackText border-borderColor border"
                            }
                        >
                            {googleSvg}
                        </Button>
                    </a>
                </div>

                <div className="text-[#515961s text-xs mt-[180px] text-center fixed bottom-5 right-0 left-0">
                    New here?{" "}
                    <span
                        onClick={() => navigate("/signup")}
                        className="text-primary cursor-pointer"
                    >
            Create an account
          </span>
                </div>
            </div>
        </div>
    );
};

export default SignIn;
