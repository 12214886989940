import React from "react";
import DashboardLayout from "../layout/DashboardLayout";
import { Link } from "react-router-dom";
import Button from "../components/buttons/DefaultButton";

const NotFound = () => {
  return (
    <DashboardLayout>
      <div className="flex flex-col justify-center gap-6 mx-auto w-fit h-[80vh]">
        <div className="w-fit mx-auto">
          <img src="/images/404.png" alt="" />
        </div>
        <div className="font-semibold text-xl text-center mt-5">
          Page not found
        </div>
        <div className="text-lg text-center font-normal leading-5 text-[#737373]">
          Sorry, we could not find the page you are looking for
        </div>
        <div className="w-fit mx-auto">
          <Link to={"/"}>
            <Button
              text={"Go to home"}
              customClass={
                "md:w-fit px-7  py-2.5 bg-primary text-white flex gap-2 md:mx-0 md:mt-0 mt-4 mx-auto items-center rounded-full cursor-pointer h-fit text-sm"
              }
            />
          </Link>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NotFound;
