// import { Link, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Header from "./header";

const FOOTER_LINKS = [
  {
    name: "Privacy Policy",
    link: "https://trycareermapcom.notion.site/CareerMap-Privacy-Policy-a3fa8031f7624ecfb4185d23539e6f2d",
  },
  {
    name: "Terms and Conditions",
    link: "https://trycareermapcom.notion.site/CareerMap-Terms-Conditions-d43b2ac65cee436894b787f7bf8b9556",
  },
  {
    name: "Refund Policy",
    link: "https://trycareermapcom.notion.site/CareerMap-Terms-Conditions-d43b2ac65cee436894b787f7bf8b9556",
  },
];
const DashboardLayout = ({ children, footer }) => {
  return (
    <div>
      <Header />
      <div className="container mx-auto  md:mb-14 md:pb-14">{children}</div>
      {footer && (
        <div className="flex justify-between flex-col md:flex-row items-center gap-2 p-5 px-7 border border-t md:fixed  left-0 right-0 bottom-0 bg-white">
          <div className="text-secondary text-xs">
            ©2024 CareerMap all rights reserved
          </div>
          <div className="flex items-center gap-2 md:gap-4">
            <Link to="mailto:accounts@trycareermap.com">
              <div className="text-xs md:text-sm whitespace-nowrap font-medium text-secondary">
                Contact
              </div>
            </Link>
            {FOOTER_LINKS.map((item, index) => {
              return (
                <Link target="_blank" key={index} to={item.link}>
                  <li className="text-xs md:text-sm whitespace-nowrap font-medium text-secondary">
                    {item.name}
                  </li>
                </Link>
              );
            })}
          </div>
          {/* <div /> */}
        </div>
      )}
    </div>
  );
};

export default DashboardLayout;
