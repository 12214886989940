const HeadingDesign = ({ text, id ,blur}) => {
  return (
    <div
      id={id}
      className={`flex items-center gap-2 pt-[32px] whitespace-nowrap text-[#1D2939] text-sm font-medium leading-normal ${blur && "blur-sm"}`}
    >
      {text}
      <hr className="w-full text-[#D0D5DD] bg-[#D0D5DD] border-[#D0D5DD]" />
    </div>
  );
};

export default HeadingDesign;
