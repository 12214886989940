import React from "react";
import { Fade } from "react-reveal";
// import FieldError from "../common/FieldError";
const TextInput = ({
  label,
  placeholder,
  name,
  onChange,
  id,
  value,
  onKeyDown,
  type,
  onKeyUp,
  customClass,
  error,
  required,
  textarea,
  labelBelow,
}) => {
  return (
    <div className="relative flex-col flex gap-1.5 mt-0 md:mt-3 w-full">
      {label && (
        <div className="text-[#0D182A] font-medium text-sm leading-7 tracking-[0.32px]">
          {label}
          {required && <span className="text-red-500 ml-1">*</span>}
        </div>
      )}
      {textarea ? (
        <textarea
          id={id}
          className={`outline-none border-b bg-transparent focus:bg-white border-grayColor h-16 placeholder:text-xs text-xs font-normal placeholder:text-[#647188] placeholder:leading-[23.8px] placeholder:tracking-[0.28px] placeholder:font-light leading-4 !text-[#8D8D99] py-3 ${customClass}`}
          placeholder={placeholder}
          type={type}
          name={name}
          onChange={onChange}
          value={value}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
        />
      ) : (
        <input
          id={id}
          className={`outline-none border-b bg-transparent  focus:bg-white border-grayColor  placeholder:text-xs text-xs font-normal placeholder:text-[#647188] placeholder:leading-[23.8px] placeholder:tracking-[0.28px] placeholder:font-light leading-4 !text-[#8D8D99] py-3 ${customClass}`}
          placeholder={placeholder}
          type={type}
          name={name}
          onChange={onChange}
          value={value}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
        />
      )}
      {error && (
        <Fade duration={900}>
          <div className="text-xs  text-red-500 duration-1000 transition-all">
            {error}
          </div>
        </Fade>
      )}
      {labelBelow && (
        <div className="text-xs font-semibold text-gray-400">{labelBelow}</div>
      )}
    </div>
  );
};

export default TextInput;
