import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "react-toastify/dist/ReactToastify.css";
import { Slide, ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ToastContainer
        transition={Slide}
        autoClose={1500}
        hideProgressBar
        draggable
      />
      <SkeletonTheme baseColor="#d7d7d7" highlightColor="#9591ee" enableAnimation >
        <App />
      </SkeletonTheme>
    </BrowserRouter>
  </React.StrictMode>
);
