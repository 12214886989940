import React, { useEffect, useState } from "react";
import Button from "../components/buttons/DefaultButton";
import Card from "../components/cardDesigns/Card";
import SkillGapCard from "../components/cardDesigns/SkillGapCard";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getSampleUserData, getUserData } from "../services/auth-services";
import Header from "../layout/header";
import { LiaGreaterThanSolid } from "react-icons/lia";
import { toast } from "react-toastify";
import HeadingDesign from "../components/cardDesigns/HeadingDesign";
import Skeleton from "react-loading-skeleton";

const sideLinks = [
  {
    text: "Career Snapshot",
  },
  {
    text: "Skill Gap Analysis",
  },
  {
    text: "Learning Ladder",
  },
  {
    text: "Certifications & Qualifications",
  },
  {
    text: "Industry Trends and Technologies",
  },
  {
    text: "Network Nexus",
  },
  {
    text: "Market Movers",
  },
  {
    text: "Project Playbook",
  },
  {
    text: "Transition Trajectories",
  },
  {
    text: "Work-Life Integration",
  },
  {
    text: "Charity Champions",
  },
  {
    text: "Career Navigation Essentials",
  },
  {
    text: "Blueprint for Success",
  },
];

const Report = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [PLAN_DATA, setData] = useState({ completed: false });
  const blur = PLAN_DATA?.is_sample ? false : false;
  const [isSideBar, setIsSideBar] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    navigate(`${location.pathname}${location.search}#${activeSection}`);
  }, [activeSection]);
  useEffect(() => {
    const handleScroll = () => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setActiveSection(entry.target.id);
            }
          });
        },
        { rootMargin: "-50% 0px -50% 0px" }
      );
      observer.observe(
        document.getElementById(sideLinks[0]?.text.replace(/ /g, ""))
      );
      observer.observe(
        document.getElementById(sideLinks[1]?.text.replace(/ /g, ""))
      );
      observer.observe(
        document.getElementById(sideLinks[2]?.text.replace(/ /g, ""))
      );
      observer.observe(
        document.getElementById(sideLinks[3]?.text.replace(/ /g, ""))
      );
      observer.observe(
        document.getElementById(sideLinks[4]?.text.replace(/ /g, ""))
      );
      observer.observe(
        document.getElementById(sideLinks[5]?.text.replace(/ /g, ""))
      );
      observer.observe(
        document.getElementById(sideLinks[6]?.text.replace(/ /g, ""))
      );
      observer.observe(
        document.getElementById(sideLinks[7]?.text.replace(/ /g, ""))
      );
      observer.observe(
        document.getElementById(sideLinks[8]?.text.replace(/ /g, ""))
      );
      observer.observe(
        document.getElementById(sideLinks[9]?.text.replace(/ /g, ""))
      );
      observer.observe(
        document.getElementById(sideLinks[10]?.text.replace(/ /g, ""))
      );
      observer.observe(
        document.getElementById(sideLinks[11]?.text.replace(/ /g, ""))
      );
      observer.observe(
        document.getElementById(sideLinks[12]?.text.replace(/ /g, ""))
      );
      return () => {
        observer.disconnect();
      };
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleGetData = (val) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const fetchData =
      location.pathname === "/report-example/"
        ? getSampleUserData
        : getUserData;

    fetchData(val, user?.access)
      .then((res) => {
        // Assuming setData and data are available in your component's scope
        if (JSON.stringify(PLAN_DATA) !== JSON.stringify(res.data)) {
          setData(res.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          const errors = error.response.data;
          if (errors.error !== "Data not found") {
            setLoading(false);
          }
          console.log("errors: ", errors);
        } else {
          toast.error("Network error.");
        }
      });
  };
  useEffect(() => {
    if (location.search) {
      handleGetData(location.search);
    }
  }, [location.search]);
  useEffect(() => {
    if (PLAN_DATA?.completed === false) {
      const interval = setInterval(() => {
        if (location.search && PLAN_DATA?.completed === false) {
          handleGetData(location.search);
        }
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [location.search])
  const handleReportPDF = () => {
    setLoading(true);
    const viewportMeta = document.querySelector("meta[name=viewport]");
    viewportMeta.setAttribute("content", "width=2560");
    const prints = document.getElementById("downloadPDF");
    html2canvas(prints, {
      allowTaint: false,
      removeContainer: true,
      backgroundColor: "#ffffff",
      scale: window.devicePixelRatio,
      useCORS: false,
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        let contentHeight = prints.scrollHeight;
        let pdfConverter = new jsPDF({
          orientation: "p",
          unit: "mm",
          format: [210, 600],
        });
        let imgWidth = pdfConverter.internal.pageSize.getWidth();
        let imgHeight = pdfConverter.internal.pageSize.getHeight();
        let heightLeft = 1440;

        let position = 0;

        pdfConverter.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= contentHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdfConverter.addPage();
          pdfConverter.addImage(
            imgData,
            "PNG",
            0,
            position,
            imgWidth,
            imgHeight
          );
          heightLeft -= contentHeight;
        }

        pdfConverter.save(`CareerMap_${PLAN_DATA.person}.pdf`);

        viewportMeta.setAttribute(
          "content",
          "width=device-width, initial-scale=1"
        );
      })
      .catch((error) => {
        console.log("error: ", error);
      });
    setLoading(false);
  };
  document.title = "CareerMap";
  const cardData = [
    { head: "Strength", key: "strengths" },
    { head: "Weakness", key: "weaknesses" },
    { head: "Opportunities", key: "opportunities" },
    { head: "Threats", key: "threats" },
  ];
  return (
    <>
      <Header />
      <div className="flex relative">
        {/* side bar  */}
        {isSideBar && (
          <div
            onClick={() => setIsSideBar(false)}
            className="fixed md:hidden  backdrop-blur-sm h-screen w-screen "
          />
        )}
        <div
          className={`bg-sideBarBg overflow-y-scroll  min-w-[320px] !z-40 transition-all duration-300 top-[4.0rem] fixed ${isSideBar ? "left-0 top-" : "-left-[20rem] "
            }  md:sticky top-0 h-[90vh] pb-5 border-r`}
        >
          <div className="px-[28px] !relative">
            <LiaGreaterThanSolid
              onClick={() => setIsSideBar(!isSideBar)}
              className={`bg-white border border-gray-400 fill-primary md:hidden  shadow-inputShadow h-10 !z-50 w-10 p-2 absolute top-0 m-3 rounded-full ${isSideBar ? "-right-8 rotate-180" : "-right-16"
                } `}
            />
            <div className="pt-10 pb-5 pl-4 text-[#667085] text-sm font-normal leading-normal">
              Career Plan
            </div>
            {sideLinks?.map((item, index) => {
              return (
                <a
                  onClick={() => setIsSideBar(!isSideBar)}
                  key={index + "career_plan"}
                  href={"#" + item?.text.replace(/ /g, "")}
                >
                  <div
                    className={`flex mb-2  border-l-[2px] border-transparent   p-3 cursor-pointer ${location.hash === "#" + item.text.replace(/ /g, "") &&
                      "bg-bgHeader !border-l-[2px] rounded-[4px]   !border-primary"
                      }`}
                  >
                    <div
                      className={`${location.hash ===
                        "#" + item.text.replace(/ /g, "") &&
                        "!text-primary !font-semibold"
                        } whitespace-nowrap text-[15px] ml-2.5 ! truncate leading-6 select-none font-normal text-blackText hover:text-primary`}
                    >
                      {item.text}
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
        <div className="bg-[#fff] w-full">
          <div className="flex justify-end py-3 px-10  bg-white">
            {loading && (
              <div
                className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-neutral-100 motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
              >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  Loading...
                </span>
              </div>
            )}
          </div>
          <div className="px-10 pb-20" id="downloadPDF">
            <div className="flex  items-center flex-col md:flex-row gap-5 justify-between">
              <div>
                <div className="pt-5 text-2xl leading-normal tracking-[-.114px] font-semibold text-[#0D182A]">
                  Hi {PLAN_DATA?.person}!
                </div>
                <div className="text-secondary font-normal leading-normal text-base">
                  Your career plan is here..
                </div>
              </div>
              {/* download buttons section  */}
              <div className=" flex gap-4">
                <Button
                  text={"Save as PDF"}
                  onClick={handleReportPDF}
                  customClass={
                    "font-medium text-sm bg-white !text-[#344054] border !border-[#D0D5DD]"
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M0.833374 9.00004C0.833374 5.26809 0.833374 3.40211 1.99274 2.24274C3.15211 1.08337 5.01809 1.08337 8.75004 1.08337C12.482 1.08337 14.348 1.08337 15.5073 2.24274C16.6667 3.40211 16.6667 5.26809 16.6667 9.00004C16.6667 12.732 16.6667 14.598 15.5073 15.7573C14.348 16.9167 12.482 16.9167 8.75004 16.9167C5.01809 16.9167 3.15211 16.9167 1.99274 15.7573C0.833374 14.598 0.833374 12.732 0.833374 9.00004Z"
                      stroke="#455571"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M8.75008 12.3334L8.75008 5.66671M8.75008 12.3334C8.16656 12.3334 7.07636 10.6715 6.66675 10.25M8.75008 12.3334C9.3336 12.3334 10.4238 10.6715 10.8334 10.25"
                      stroke="#455571"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
                {blur && (
                  <Button
                    onClick={() => navigate("/plans")}
                    customClass={"text-sm"}
                    text={"Get Full Report"}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="20"
                      viewBox="0 0 17 20"
                      fill="none"
                    >
                      <path
                        d="M15.75 9.16663V8.33329C15.75 5.1906 15.75 3.61925 14.7163 2.64294C13.6825 1.66663 12.0187 1.66663 8.69118 1.66663L7.80883 1.66663C4.48126 1.66663 2.81748 1.66663 1.78374 2.64294C0.75 3.61925 0.75 5.1906 0.75 8.33329L0.75 11.6666C0.75 14.8093 0.75 16.3807 1.78374 17.357C2.81748 18.3333 4.48126 18.3333 7.80882 18.3333H8.25"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M4.91669 5.83325H11.5834"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M4.91669 10H9.08335"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M15.75 17.2058L15.75 14.1666C15.75 12.9754 14.6307 11.6666 13.25 11.6666C11.8693 11.6666 10.75 12.9754 10.75 14.1666L10.75 17.0833C10.75 17.733 11.3605 18.3333 12.1136 18.3333C12.8668 18.3333 13.4773 17.733 13.4773 17.0833L13.4773 14.8039"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                  </Button>
                )}
              </div>
            </div>
            <section id={sideLinks[0]?.text.replace(/ /g, "")}>
              <HeadingDesign
                id={sideLinks[0]?.text.replace(/ /g, "")}
                text={sideLinks[0].text}
              />
              {PLAN_DATA?.CareerSnapshot ?
                <>
                  <Card
                    head={"Current Professional Standing"}
                    description={PLAN_DATA?.CareerSnapshot?.current_role}
                  />
                  <Card
                    head={"Strategic Overview for Transition"}
                    description={PLAN_DATA?.CareerSnapshot?.transition_path}
                  />
                </>
                : <>
                  <Skeleton count={1} height={30} className="mt-5" width={200} />
                  <Skeleton count={1} height={150} className="mt-2" />
                  <Skeleton count={1} height={30} className="mt-5" width={200} />
                  <Skeleton count={1} height={150} className="mt-2" />
                </>}
              <div
                className={`text-lg text-[#0D182A] font-semibold leading-[26px] w-full !backdrop-blur-xl bl ${blur && "blur"
                  } max-h-full pt-6 `}
              >
                SWOT Analysis
              </div>
              {PLAN_DATA?.StepsFollow ? cardData.map((data) => (
                <div>
                  <ul className="list-disc list-inside">
                    <li>
                      <span className=" text-[#0D182A] font-semibold leading-[26px]">
                        {data.head}:
                      </span>{" "}
                      <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                        {PLAN_DATA?.StepsFollow?.[data.key]?.join(" ")}
                      </span>
                    </li>
                  </ul>
                </div>
              )) : <Skeleton count={4} className="mt-5" height={50} />}
            </section>
            <section id={sideLinks[1]?.text.replace(/ /g, "")}>
              <HeadingDesign
                blur={blur}
                id={sideLinks[1]?.text.replace(/ /g, "")}
                text={sideLinks[1].text}
              />
              {PLAN_DATA?.SkillGapAnalysis ?
                <>
                  <Card
                    blur={blur}
                    cardHeight={"!mf:w-2/5"}
                    head={"Assessment of Competencies"}
                    description={PLAN_DATA?.SkillGapAnalysis?.overview}
                  />
                  <SkillGapCard blur={blur} head={"Skill Gap Analysis Table"}>
                    <div className="table-responsive w-full">
                      <table className="table-aut0 w-full  text-left py-2 overflow-scroll border-b">
                        <thead>
                          <tr className="h-20 border-b">
                            <th className="px-0">Current Skills</th>
                            <th>Required Skills</th>
                            <th>Gap Identification</th>
                          </tr>
                        </thead>
                        <tbody className="!text-[15px] leading-[22.2px] font-normal text-[#455571] pb-5">
                          {PLAN_DATA &&
                            PLAN_DATA.SkillGapAnalysis?.table?.map(
                              (item, index) => {
                                return (
                                  <tr className="h-20 border-b">
                                    <td>{item?.current_skill ? item?.current_skill : '- - - - - - - - - -'}</td>
                                    <td>{item?.skills_required ? item?.skills_required : "- - - - - - - - - -"}</td>
                                    <td>{item?.identify_gap ? item?.identify_gap : "- - - - - - - - - -"}</td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      </table>
                    </div>
                  </SkillGapCard>
                </>
                :
                <>
                  <Skeleton count={1} height={150} />
                  <Skeleton count={4} height={70} />
                </>
              }
            </section>
            <HeadingDesign
              blur={blur}
              id={sideLinks[2]?.text.replace(/ /g, "")}
              text={sideLinks[2].text}
            />
            {PLAN_DATA?.LearningLadderCourses ?
              <div className="">
                <div>
                  <ul className="list-disc list-inside">
                    {PLAN_DATA?.LearningLadderCourses?.edx?.map(
                      (item, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              className="text-lg pt-6 text-[#0D182A] font-semibold leading-[26px]"
                            >
                              EDX: {item?.course_name}
                            </div>
                            <Link to={item?.website} target="_blank">
                              <li>
                                <span className=" text-[#0D182A] font-semibold leading-[26px]">
                                  Website:
                                </span>{" "}
                                <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                                  {item?.website.replace("https://www.", "")}
                                </span>
                              </li>
                            </Link>
                            <li>
                              <span className=" text-[#0D182A] font-semibold leading-[26px]">
                                Description:
                              </span>{" "}
                              <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                                {item?.description}
                              </span>
                            </li>
                            <li>
                              <span className=" text-[#0D182A] font-semibold leading-[26px]">
                                Cost:
                              </span>{" "}
                              <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                                {item?.currency + " " + item?.cost}
                              </span>
                            </li>
                            <li>
                              <span className=" text-[#0D182A] font-semibold leading-[26px]">
                                Value:
                              </span>{" "}
                              <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                                {item?.value}
                              </span>
                            </li>
                          </>
                        );
                      }
                    )}
                  </ul>
                </div>
                <div>
                  <ul className="list-disc list-inside">
                    {PLAN_DATA?.LearningLadderCourses?.coursera?.map(
                      (item, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              className="text-lg pt-6 text-[#0D182A] font-semibold leading-[26px]"
                            >
                              Coursera: {item?.course_name}
                            </div>
                            <Link to={item?.website} target="_blank">
                              <li>
                                <span className=" text-[#0D182A] font-semibold leading-[26px]">
                                  Website:
                                </span>{" "}
                                <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                                  {item?.website.replace("https://www.", "")}
                                </span>
                              </li>
                            </Link>
                            <li>
                              <span className=" text-[#0D182A] font-semibold leading-[26px]">
                                Description:
                              </span>{" "}
                              <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                                {item?.description}
                              </span>
                            </li>
                            <li>
                              <span className=" text-[#0D182A] font-semibold leading-[26px]">
                                Cost:
                              </span>{" "}
                              <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                                {item?.currency + " " + item?.cost}
                              </span>
                            </li>
                            <li>
                              <span className=" text-[#0D182A] font-semibold leading-[26px]">
                                Value:
                              </span>{" "}
                              <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                                {item?.value}
                              </span>
                            </li>
                          </>
                        );
                      }
                    )}
                  </ul>
                </div>
                <div>
                  <ul className="list-disc list-inside">
                    {PLAN_DATA?.LearningLadderCourses?.udemy?.map(
                      (item, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              className="text-lg pt-6 text-[#0D182A] font-semibold leading-[26px]"
                            >
                              Udemy: {item?.course_name}
                            </div>
                            <Link to={item?.website} target="_blank">
                              <li>
                                <span className=" text-[#0D182A] font-semibold leading-[26px]">
                                  Website:
                                </span>{" "}
                                <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                                  {item?.website.replace("https://www.", "")}
                                </span>
                              </li>
                            </Link>
                            <li>
                              <span className=" text-[#0D182A] font-semibold leading-[26px]">
                                Description:
                              </span>{" "}
                              <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                                {item?.description}
                              </span>
                            </li>
                            <li>
                              <span className=" text-[#0D182A] font-semibold leading-[26px]">
                                Cost:
                              </span>{" "}
                              <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                                {item?.currency + " " + item?.cost}
                              </span>
                            </li>
                            <li>
                              <span className=" text-[#0D182A] font-semibold leading-[26px]">
                                Value:
                              </span>{" "}
                              <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                                {item?.value}
                              </span>
                            </li>
                          </>
                        );
                      }
                    )}
                  </ul>
                </div>
              </div>
              :
              <>
                <Skeleton count={1} height={25} className="mt-5" width={200} />
                <Skeleton count={1} height={150} className="mt-2" />
                <Skeleton count={1} height={25} className="mt-5" width={200} />
                <Skeleton count={1} height={150} className="mt-2" />
                <Skeleton count={1} height={25} className="mt-5" width={200} />
                <Skeleton count={1} height={150} className="mt-2" />
              </>
            }
            <section>
              <HeadingDesign
                blur={blur}
                id={sideLinks[3]?.text.replace(/ /g, "")}
                text={sideLinks[3].text}
              />
              <div className="">
                {PLAN_DATA?.CertificationList?.certification ? PLAN_DATA?.CertificationList?.certification.map((item, index) => {
                  return (
                    <div>
                      <ul className="list-disc list-inside">
                        <div className="text-lg pt-6 text-[#0D182A] font-semibold leading-[26px]">
                          {item?.name}
                        </div>
                        <li>
                          <span className=" text-[#0D182A] font-semibold leading-[26px]">
                            Governing Body:
                          </span>{" "}
                          <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                            {item?.governing_body}
                          </span>
                        </li>
                        {/* <li>
                            <span className=" text-[#0D182A] font-semibold leading-[26px]">
                              Cost:
                            </span>{" "}
                            <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                              {item?.currency + " " + item?.cost}
                            </span>
                          </li> */}
                        <li>
                          <span className=" text-[#0D182A] font-semibold leading-[26px]">
                            Time to Complete:
                          </span>{" "}
                          <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                            {item?.time_to_complete} months
                          </span>
                        </li>
                        <li>
                          <span className=" text-[#0D182A] font-semibold leading-[26px]">
                            Description:
                          </span>{" "}
                          <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                            {" "}
                            {item?.description}
                          </span>
                        </li>
                      </ul>
                    </div>
                  )
                }) :
                  <>
                    <Skeleton count={1} height={25} className="mt-5" width={200} />
                    <Skeleton count={1} height={150} className="mt-2" />
                    <Skeleton count={1} height={25} className="mt-5" width={200} />
                    <Skeleton count={1} height={150} className="mt-2" />
                    <Skeleton count={1} height={25} className="mt-5" width={200} />
                    <Skeleton count={1} height={150} className="mt-2" />
                  </>
                }

              </div>
            </section>
            <HeadingDesign
              blur={blur}
              id={sideLinks[4]?.text.replace(/ /g, "")}
              text={sideLinks[4].text}
            />
            <div className="flex flex-col gap-8">
              <div className="">
                <div>
                  <div className="text-lg pt-6 text-[#0D182A] font-semibold leading-[26px]">
                    Trends
                  </div>
                  {PLAN_DATA?.TrendsTechnologies ?
                    <ol className="list-decimal list-inside flex flex-col gap-8">
                      <li>
                        <span className=" text-[#0D182A] font-semibold leading-[26px]">
                          Sustainable Investing:{" "}
                        </span>{" "}
                        <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                          {PLAN_DATA?.TrendsTechnologies?.trends[0]?.description}
                        </span>
                      </li>
                      <li>
                        <span className=" text-[#0D182A] font-semibold leading-[26px]">
                          Personalization and Digitalization:{" "}
                        </span>{" "}
                        <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                          {PLAN_DATA?.TrendsTechnologies?.trends[1]?.description}

                        </span>
                      </li>
                    </ol>
                    :
                    <>
                      <Skeleton count={1} height={25} className="mt-5" width={200} />
                      <Skeleton count={1} height={150} className="mt-2" />
                      <Skeleton count={1} height={25} className="mt-5" width={200} />
                      <Skeleton count={1} height={150} className="mt-2" />
                    </>
                  }
                </div>
              </div>
              <div className="">
                <div>
                  <div className="text-lg pt-6 text-[#0D182A] font-semibold leading-[26px]">
                    Technologies
                  </div>
                  {PLAN_DATA?.TrendsTechnologies ?

                    <ol className="list-decimal list-inside flex flex-col gap-8">
                      <li>
                        <span className=" text-[#0D182A] font-semibold leading-[26px]">
                          Blockchain and Cryptocurrency:{" "}
                        </span>{" "}
                        <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                          {PLAN_DATA?.TrendsTechnologies?.technologies[0]?.description}
                        </span>
                      </li>
                      <li>
                        <span className=" text-[#0D182A] font-semibold leading-[26px]">
                          Artificial Intelligence and Machine Learning:{" "}
                        </span>{" "}
                        <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                          {PLAN_DATA?.TrendsTechnologies?.technologies[1]?.description}
                        </span>
                      </li>
                    </ol>
                    :
                    <>
                      <Skeleton count={1} height={25} className="mt-5" width={200} />
                      <Skeleton count={1} height={150} className="mt-2" />
                      <Skeleton count={1} height={25} className="mt-5" width={200} />
                      <Skeleton count={1} height={150} className="mt-2" />
                    </>
                  }
                </div>
              </div>
            </div>
            <HeadingDesign
              blur={blur}
              id={sideLinks[5]?.text.replace(/ /g, "")}
              text={sideLinks[5].text}
            />
            {PLAN_DATA?.NetworkNexusList ?
              <>
                <div className="pt-6">
                  <span className=" text-[#0D182A] font-semibold leading-[26px]">
                    How to Network:{" "}
                  </span>{" "}
                  <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                    {PLAN_DATA?.NetworkNexusList?.how_to_network_nexus}
                  </span>
                </div>
                <span className=" text-[#0D182A] font-semibold leading-[26px]">
                  Who to Network With:{" "}
                </span>
                <ol className="list-decimal list-inside flex flex-col gap-4">
                  {PLAN_DATA?.NetworkNexusList?.who_to_network?.map((item, index) => {
                    return (
                      <li>
                        <span className=" text-[#0D182A] font-semibold leading-[26px]">
                          {item?.designation}
                        </span>{" "}
                        <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                          {item?.benefit}
                        </span>
                      </li>
                    )
                  })}
                </ol>
                <div className="pt-6">
                  <span className=" text-[#0D182A] font-semibold leading-[26px]">
                    Where to Network:{" "}
                  </span>{" "}
                  <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                    {PLAN_DATA?.NetworkNexusList?.where_to_network}
                  </span>
                </div>
                <div className="pt-6">
                  <span className=" text-[#0D182A] font-semibold leading-[26px]">
                    Pro Tip:{" "}
                  </span>{" "}
                  <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                    {PLAN_DATA?.NetworkNexusList?.pro_tip}
                  </span>
                </div>
              </> :
              <>
                <Skeleton count={1} height={100} className="mt-5" />
                <Skeleton count={5} height={25} className="mt-5" />
                <Skeleton count={1} height={100} className="mt-5" />
              </>
            }
            <HeadingDesign
              id={sideLinks[6]?.text.replace(/ /g, "")}
              blur={blur}
              text={sideLinks[6].text}
            />
            {PLAN_DATA?.MarketMovers ?
              <>
                <Card
                  blur={blur}
                  head={PLAN_DATA?.MarketMovers?.heading}
                  description={PLAN_DATA?.MarketMovers?.description}
                />
              </>
              :
              <>
                <Skeleton count={1} height={25} className="mt-5" width={200} />
                <Skeleton count={1} height={150} className="mt-2" />
                <Skeleton count={1} height={25} className="mt-5" width={200} />
                <Skeleton count={1} height={150} className="mt-2" />
              </>
            }

            <HeadingDesign
              id={sideLinks[7]?.text.replace(/ /g, "")}
              blur={blur}
              text={sideLinks[7].text}
            />
            <ol className="list-decimal list-inside flex flex-col gap-8 pt-6">
              {PLAN_DATA?.ProjectPlayBookList?.project_playbook ? PLAN_DATA?.ProjectPlayBookList?.project_playbook.map((item, index) => {
                return (

                  <li>
                    <span className=" text-[#0D182A] font-semibold leading-[26px]">
                      {item?.name}:
                    </span>
                    <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                      {item?.description}
                    </span>
                  </li>
                )
              }) : <Skeleton count={3} height={200} className="mt-5" />}

            </ol>
            <HeadingDesign
              id={sideLinks[8]?.text.replace(/ /g, "")}
              blur={blur}
              text={sideLinks[8].text}
            />
            <ol className="list-decimal list-inside flex flex-col gap-4 pt-6">
              {PLAN_DATA?.TransactionTrajectoryList ? PLAN_DATA?.TransactionTrajectoryList?.transaction_trajectory.map((item, index) => {
                return (
                  <>
                    <li>
                      <span className=" text-[#0D182A] font-semibold leading-[26px]">
                        {item?.name}:
                      </span>{" "}
                      <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                        {item?.description}
                      </span>
                    </li>
                  </>
                )
              })
                :
                <>
                  <Skeleton count={1} height={25} className="mt-5" width={200} />
                  <Skeleton count={1} height={150} className="mt-2" />
                  <Skeleton count={1} height={25} className="mt-5" width={200} />
                  <Skeleton count={1} height={150} className="mt-2" />
                </>
              }
            </ol>


            <HeadingDesign
              id={sideLinks[9]?.text.replace(/ /g, "")}
              text={sideLinks[9].text}
              blur={blur}
            />
            {PLAN_DATA?.WorkLifeStrategy ?
              <>

                <div className="pt-6">
                  {" "}
                  <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                    {" "}
                    {PLAN_DATA.WorkLifeStrategy?.strategy_description}
                  </span>
                </div>
                {/* <ol className="list-decimal list-inside flex flex-col gap-8 pt-6"> */}
                <div className="pt-6">
                  <span className=" text-[#0D182A] font-semibold leading-[26px]">
                    {PLAN_DATA.WorkLifeStrategy?.tip1_heading}:
                  </span>{" "}
                  <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                    {PLAN_DATA.WorkLifeStrategy?.tip1_description}:
                  </span>
                </div>
                <div className="pt-6">
                  <span className=" text-[#0D182A] font-semibold leading-[26px]">
                    {PLAN_DATA.WorkLifeStrategy?.tip2_heading}:

                  </span>{" "}
                  <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                    {PLAN_DATA.WorkLifeStrategy?.tip2_description}:
                  </span>
                </div>
              </>
              :
              <>
                <Skeleton count={1} height={25} className="mt-5" width={200} />
                <Skeleton count={1} height={150} className="mt-2" />
                <Skeleton count={1} height={25} className="mt-5" width={200} />
                <Skeleton count={1} height={150} className="mt-2" />
              </>
            }
            {/* </ol> */}
            <HeadingDesign
              id={sideLinks[10]?.text.replace(/ /g, "")}
              blur={blur}
              text={sideLinks[10].text}
            />
            <ol className="list-decimal list-inside flex flex-col gap-8 pt-6">
              {PLAN_DATA?.CharityChampionList ? PLAN_DATA?.CharityChampionList.charity_champion.map((item, index) => {
                return (
                  <li>
                    <span className=" text-[#0D182A] font-semibold leading-[26px]">
                      {item?.heading}
                    </span>{" "}
                    <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                      {item?.description}
                    </span>
                  </li>
                )
              }) :
                <Skeleton count={3} height={50} className="mt-5" />
              }
            </ol>
            <HeadingDesign
              id={sideLinks[11]?.text.replace(/ /g, "")}
              text={sideLinks[11].text}
              blur={blur}
            />
            <div className="flex flex-col md:flex-row gap-8">
              <SkillGapCard blur={blur}>
                <div className="table-responsive">
                  {PLAN_DATA?.RecruiterList?.recruiter && PLAN_DATA?.RecruiterList?.recruiter && PLAN_DATA?.RecruiterList?.recruiter ?
                    <table className="table-auto text-left py-5 border-b w-full">
                      <thead>
                        <tr className="h-20 border-b">
                          <th>Recruiter Rolodex</th>
                          <th>Top Employers</th>
                          <th>Career Connectors</th>
                        </tr>
                      </thead>
                      <tbody className="!text-[15px] leading-[22.2px] font-normal text-[#455571] pb-5">
                        <tr className="h-20 border-b">
                          <td>
                            {PLAN_DATA?.RecruiterList?.recruiter[0] !== "" &&
                              PLAN_DATA?.RecruiterList?.recruiter[0]}
                          </td>
                          <td>
                            {PLAN_DATA?.EmployerList?.employer[0] !== "" &&
                              PLAN_DATA?.EmployerList?.employer[0]}
                          </td>
                          <td>
                            {PLAN_DATA?.EmployerList?.employer[0] !== "" &&
                              PLAN_DATA?.JobBoardList?.job_board[0]}
                          </td>
                        </tr>
                        <tr className="h-20 border-t border-b">
                          <td>
                            {PLAN_DATA?.RecruiterList?.recruiter[1] !== "" &&
                              PLAN_DATA?.RecruiterList?.recruiter[1]}
                          </td>
                          <td>
                            {PLAN_DATA?.EmployerList?.employer[1] !== "" &&
                              PLAN_DATA?.EmployerList?.employer[1]}
                          </td>
                          <td>
                            {PLAN_DATA?.EmployerList?.employer[1] !== "" &&
                              PLAN_DATA?.JobBoardList?.job_board[1]}
                          </td>
                        </tr>
                        <tr className="h-20 border-t border-b">
                          <td>
                            {PLAN_DATA?.RecruiterList?.recruiter[2] !== "" &&
                              PLAN_DATA?.RecruiterList?.recruiter[2]}
                          </td>
                          <td>
                            {PLAN_DATA?.EmployerList?.employer[2] !== "" &&
                              PLAN_DATA?.EmployerList?.employer[2]}
                          </td>
                          <td>
                            {PLAN_DATA?.EmployerList?.employer[2] !== "" &&
                              PLAN_DATA?.JobBoardList?.job_board[2]}
                          </td>
                        </tr>
                        <tr className="h-20 border-t">
                          <td>
                            {PLAN_DATA?.RecruiterList?.recruiter[3] !== "" &&
                              PLAN_DATA?.RecruiterList?.recruiter[3]}
                          </td>
                          <td>
                            {PLAN_DATA?.EmployerList?.employer[3] !== "" &&
                              PLAN_DATA?.EmployerList?.employer[3]}
                          </td>
                          <td>
                            {PLAN_DATA?.EmployerList?.employer[3] !== "" &&
                              PLAN_DATA?.JobBoardList?.job_board[3]}
                          </td>
                        </tr>
                        <tr className="h-20 border-t">
                          <td>
                            {PLAN_DATA?.RecruiterList?.recruiter[4] !== "" &&
                              PLAN_DATA?.RecruiterList?.recruiter[4]}
                          </td>
                          <td>
                            {PLAN_DATA?.EmployerList?.employer[4] !== "" &&
                              PLAN_DATA?.EmployerList?.employer[4]}
                          </td>
                          <td>
                            {PLAN_DATA?.EmployerList?.employer[4] !== "" &&
                              PLAN_DATA?.JobBoardList?.job_board[4]}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    : <Skeleton count={5} height={60} className="mt-5" />}
                </div>
              </SkillGapCard>
            </div>
            <HeadingDesign
              id={sideLinks[12]?.text.replace(/ /g, "")}
              text={sideLinks[12].text}
              blur={blur}
            />
            {PLAN_DATA?.SuccessBluePrintList ?
              <>
                <div className="pt-6">
                  <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                    {PLAN_DATA?.SuccessBluePrintList.summary}
                  </span>
                </div>
                <div className="pt-6 flex flex-col">
                  {PLAN_DATA?.SuccessBluePrintList.success_blue_print.map((item, index) =>
                    <>
                      <span className=" text-[#0D182A] font-semibold leading-[26px]">
                        {item.paragraph_heading}
                      </span>{" "}
                      <span className="!text-[15px] leading-[22.2px] font-normal text-[#455571]">
                        {item?.paragraph_descriptiom}
                      </span>
                      <br />
                    </>
                  )}
                </div>
              </>
              :
              <>
                <Skeleton count={1} className="mt-5" height={50} />
                <Skeleton count={3} className="mt-5" height={100} />
                <Skeleton count={1} className="mt-5" height={50} />
              </>
            }
          </div>
        </div>
      </div>
    </>
  );
};
export default Report;
