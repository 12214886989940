import React, { useState } from "react";
import Header from "../layout/header";
import TextInput from "../components/inputs/TextInput";
import Button from "../components/buttons/DefaultButton";
import { useNavigate } from "react-router-dom";
import { ERROR } from "../utils/contants";
import { regexEmail } from "../utils/helpers";
import { toast } from "react-toastify";
import { resetPassword } from "../services/auth-services";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Swal from "sweetalert2";

const DEFAULT_FIELDS = {
  email: "",
};
const ResetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState(DEFAULT_FIELDS);
  function checkValidations() {
    if (!regexEmail(credentials.email)) {
      toast.warn(ERROR.EMAIL_VALIDATION);
      setLoading(false);
      return false;
    }
    return true;
  }
  const handleResetPassword = () => {
    setLoading(true);
    if (checkValidations()) {
      resetPassword(credentials)
        .then((res) => {
          Swal.fire({
            text: "Check your email to reset password.",
            icon: "success",
          });
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err.message);
          setLoading(false);
        });
    }
  };
  const onChangeFields = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  return (
    <div className="mt-20/">
      <Header />
      <div className="w-full mt-20 h-[70vh] flex flex-col justify-center items-center m-auto">
        <div className=" w-[400px] flex flex-col gap-4">
          <div className="text-2xl font-semibold leading-[35px] text-center">
            Reset Password
          </div>
          <TextInput
            name="email"
            onChange={(e) => onChangeFields(e)}
            placeholder={"Enter your email"}
          />
          <Button
            text={
              loading ? (
                <AiOutlineLoading3Quarters className="text-2xl animate-spin" />
              ) : (
                "Send Email"
              )
            }
            onClick={handleResetPassword}
            customClass={"!w-full !mt-2 !py-2 !font-normal !text-[12px]"}
          />
        </div>

        <div className="text-[#515961s text-xs mt-[180px] text-center fixed bottom-5 right-0 left-0">
          New here?{" "}
          <span
            onClick={() => navigate("/signup")}
            className="text-primary cursor-pointer"
          >
            Create an account
          </span>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
