import React, { useEffect, useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import Button from "../components/buttons/DefaultButton";
import TextInput from "../components/inputs/TextInput";
import ProfileSidebar from "../components/ProfileSidebar";
import { changeProfile, getProfile } from "../services/auth-services";
import { toast } from "react-toastify";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const AccountSettings = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    user_name: "",
    last_name: "",
  });
  const handleChange = (e) => {
    const { value, name } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);
    const user = JSON.parse(localStorage.getItem("user"));
    changeProfile(user.access, data)
      .then((res) => {
        setData(res.data);
        toast.success("Changed successfully!");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          const errors = error.response.data;
          Object.keys(errors).forEach((key) => {
            if (Array.isArray(errors[key])) {
              errors[key].forEach((message) => {
                toast.error(`${message}`);
              });
            } else {
              toast.error(`${errors[key]}`);
            }
          });
        } else {
          toast.error("Network error.");
        }
      });
  };

  const getProfileData = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    getProfile(user.access)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    getProfileData();
  }, []);
  return (
    <DashboardLayout footer>
      <div className="flex flex-col p-5 md:w-3/5 md:mt-10 mx-auto">
        <div className="mx-auto">
          <ProfileSidebar data={data} />
        </div>
        <div className="basis-full mx-5">
          <h5 className="py-5 font-semibold text-xl text-[#110229]">
            Change name
          </h5>
          <TextInput
            label={"First Name"}
            name="first_name"
            value={data?.first_name}
            onChange={(e) => handleChange(e)}
            placeholder={"Enter your first name"}
          />
          <TextInput
            label={"Last Name"}
            name="last_name"
            value={data?.last_name}
            onChange={(e) => handleChange(e)}
            placeholder={"Enter your last name"}
          />
          <div className="flex justify-center w-32 mx-auto my-8">
            <Button
              text={
                loading ? (
                  <AiOutlineLoading3Quarters
                    size={18}
                    className="animate-spin"
                  />
                ) : (
                  "Save"
                )
              }
              onClick={handleSubmit}
              customClass="bg-primary rounded-md  text-sm font-semibold text-white !w-32 !h-10"
            />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AccountSettings;
