import React, { useEffect, useState } from "react";
import PlanCard from "../components/cardDesigns/PlanCard";
import { getPrices, paymentSession } from "../services/auth-services";
import DashboardLayout from "../layout/DashboardLayout";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Loading from "../components/loading";

const Plans = () => {
  const loadingDuration = 5000; // 2 minutes
  const stepTime = 500; // Update interval in milliseconds
  const user = JSON.parse(localStorage.getItem("user"));
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [plansData, setPlansData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [currentPercentage, setCurrentPercentage] = useState(0);
  const startLoading = () => {
    const interval = setInterval(() => {
      setCurrentPercentage((oldPercentage) => {
        let newPercentage = oldPercentage + (stepTime / loadingDuration) * 100;
        if (newPercentage > 99) {
          newPercentage = 99;
          clearInterval(interval);
        }
        return newPercentage;
      });
    }, stepTime);
    return interval;
  };
  const handleSession = (index, item) => {
    setPaymentLoading(true);
    startLoading();
    setSelectedPlan(index);
    let body = { price_id: item.id };
    paymentSession(body, user?.access)
      .then((res) => {
        let url = res.data.session_url;
        setPaymentLoading(false);
        window.location.href = url;
      })
      .catch((err) => {
        setPaymentLoading(false);
        toast.error(err.message);
      });
  };
  const getPayments = () => {
    getPrices(user.access)
      .then((res) => {
        setLoading(false);
        setPlansData(res.data);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          const errors = error.response.data;
          Object.keys(errors).forEach((key) => {
            if (Array.isArray(errors[key])) {
              errors[key].forEach((message) => {
                toast.error(`${message}`);
              });
            } else {
              toast.error(`${errors[key]}`);
            }
          });
        } else {
          toast.error("Network error.");
        }
      });
  };
  useEffect(() => {
    getPayments();
    // eslint-disable-next-line
  }, []);
  return (
    <DashboardLayout footer>
      <Loading
        text={"planing your career with CareerMap"}
        {...{ currentPercentage }}
        loading={paymentLoading}
      />
      <div className="flex flex-col mt-20 pb-5  items-center justify-center gap-6">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            height={150}
            width={150}
            version="1.1"
            id="Layer_1"
            viewBox="0 0 504.123 504.123"
            xmlSpace="preserve"
          >
            <circle
              style={{ fill: "#4F46E5" }}
              cx="252.062"
              cy="252.062"
              r="252.062"
            />
            <path
              style={{ fill: "#324A5E" }}
              d="M365.883,409.6H137.846c-9.846,0-17.723-7.877-17.723-17.723V112.64  c0-9.846,7.877-17.723,17.723-17.723h228.037c9.846,0,17.723,7.877,17.723,17.723v279.237C384,401.723,376.123,409.6,365.883,409.6z  "
            />
            <rect
              x="144.542"
              y="120.911"
              style={{ fill: "#EDEDED" }}
              width="214.646"
              height="233.945"
            />
            <circle
              style={{ fill: "#2B3B4E" }}
              cx="252.062"
              cy="381.243"
              r="15.36"
            />
            <path
              style={{ fill: "#54C0EB" }}
              d="M316.258,339.495h-128c-4.726,0-8.271-3.545-8.271-8.271v-82.708c0-4.726,3.545-8.271,8.271-8.271  h128c4.726,0,8.271,3.545,8.271,8.271v82.708C324.529,335.951,320.591,339.495,316.258,339.495z"
            />
            <polygon
              style={{ fill: "#2B3B4E" }}
              points="191.015,296.172 184.714,289.871 191.015,283.569 "
            />
            <g>
              <rect
                x="196.135"
                y="320.197"
                style={{ fill: "#FFFFFF" }}
                width="15.36"
                height="7.089"
              />
              <rect
                x="220.16"
                y="320.197"
                style={{ fill: "#FFFFFF" }}
                width="15.36"
                height="7.089"
              />
              <rect
                x="244.578"
                y="320.197"
                style={{ fill: "#FFFFFF" }}
                width="15.36"
                height="7.089"
              />
              <rect
                x="268.603"
                y="320.197"
                style={{ fill: "#FFFFFF" }}
                width="15.36"
                height="7.089"
              />
              <rect
                x="293.022"
                y="320.197"
                style={{ fill: "#FFFFFF" }}
                width="15.36"
                height="7.089"
              />
            </g>
            <g>
              <rect
                x="196.135"
                y="289.871"
                style={{ fill: "#324A5E" }}
                width="33.871"
                height="7.089"
              />
              <rect
                x="196.135"
                y="305.231"
                style={{ fill: "#324A5E" }}
                width="48.837"
                height="7.089"
              />
            </g>
            <path
              style={{ fill: "#FFD05B" }}
              d="M218.191,281.994h-17.723c-2.363,0-4.726-1.969-4.726-4.726v-17.723c0-2.363,1.969-4.726,4.726-4.726  h17.723c2.363,0,4.726,1.969,4.726,4.726v17.723C222.917,280.025,220.948,281.994,218.191,281.994z"
            />
            <g>
              <polygon
                style={{ fill: "#F9B54C" }}
                points="205.194,255.212 202.831,255.212 202.831,263.877 196.135,263.877 196.135,266.24    205.194,266.24  "
              />
              <polygon
                style={{ fill: "#F9B54C" }}
                points="215.828,263.877 215.828,255.212 213.465,255.212 213.465,266.24 222.917,266.24    222.917,263.877  "
              />
              <polygon
                style={{ fill: "#F9B54C" }}
                points="196.135,273.329 208.345,273.329 208.345,281.994 210.708,281.994 210.708,273.329    222.917,273.329 222.917,270.572 196.135,270.572  "
              />
            </g>
            <polygon
              style={{ fill: "#FF6D3A" }}
              points="161.477,181.563 102.006,181.563 144.542,120.911 187.471,120.911 "
            />
            <polygon
              style={{ fill: "#FFFFFF" }}
              points="221.735,181.563 161.871,181.563 187.471,120.911 230.4,120.911 "
            />
            <g>
              <polygon
                style={{ fill: "#FF6D3A" }}
                points="282.388,181.563 222.523,181.563 231.188,120.911 273.723,120.911  "
              />
              <polygon
                style={{ fill: "#FF6D3A" }}
                points="402.117,181.563 342.646,181.563 316.652,120.911 359.582,120.911  "
              />
            </g>
            <polygon
              style={{ fill: "#FFFFFF" }}
              points="342.646,181.563 282.782,181.563 274.117,120.911 316.652,120.911 "
            />
            <g>
              <path
                style={{ fill: "#EB5425" }}
                d="M102.006,181.563v14.572c0,16.542,13.391,29.932,29.932,29.932s29.932-13.391,29.932-29.932v-14.572   H102.006z"
              />
              <path
                style={{ fill: "#EB5425" }}
                d="M222.523,181.563v14.572c0,16.542,13.391,29.932,29.932,29.932s29.932-13.391,29.932-29.932v-14.572   H222.523z"
              />
              <path
                style={{ fill: "#EB5425" }}
                d="M342.646,181.563v14.572c0,16.542,13.391,29.932,29.932,29.932l0,0   c16.542,0,29.932-13.391,29.932-29.932v-14.572H342.646z"
              />
            </g>
            <g>
              <path
                style={{ fill: "#D7D6D4" }}
                d="M161.871,181.563v14.572c0,16.542,13.391,29.932,29.932,29.932s29.932-13.391,29.932-29.932v-14.572   H161.871z"
              />
              <path
                style={{ fill: "#D7D6D4" }}
                d="M282.782,181.563v14.572c0,16.542,13.391,29.932,29.932,29.932   c16.542,0,29.932-13.391,29.932-29.932v-14.572H282.782z"
              />
            </g>
          </svg>
        </div>
        <div className="pb-5 pt-3 text-2xl leading-normal tracking-[-.114px] font-semibold text-primary text-center ">
          Purchase credits below
        </div>
        <div className="w-[90%] !mx-auto flex flex-col md:flex-row items-center gap-10 justify-center  justify-items-center">
          {loading ? (
            <>
              <Skeleton
                className="!w-[200px] !h-[150px] mt-5 !flex !flex-col "
                count={1}
              />
              <Skeleton
                className="!w-[200px] !h-[150px] mt-5 !flex !flex-col "
                count={1}
              />
              <Skeleton
                className="!w-[200px] !h-[150px] mt-5 !flex !flex-col "
                count={1}
              />
            </>
          ) : (
            plansData?.map((item, index) => (
              <PlanCard
                key={index}
                onClick={() => handleSession(index, item)}
                {...{
                  item,
                  selectedPlan,
                  setSelectedPlan,
                  index,
                  handleSession,
                  paymentLoading,
                }}
              />
            ))
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Plans;
