import React from "react";

const SkillGapCard = ({
  head,
  description = true,
  children,
  cardHeight,
  descriptionCss,
  label,
  blur,
}) => {
  return (
    <div
      className={`car w-full !backdrop-blur-xl bl ${
        blur && "blur"
      } max-h-full pt-6 ${cardHeight}`}
    >
      <div className="text-lg text-[#0D182A] font-semibold leading-[26px]">
        {head}
      </div>
      {children}
    </div>
  );
};

export default SkillGapCard;
