import React from "react";
import DataTable from "react-data-table-component";

// const conditionalRowStyles = [
//   {
//     when: (row) => row.mid_row,
//     style: (row) => ({
//       borderTop: row.first_row && '1px solid #56d5bb',
//       borderBottom: row.last_row && '1px solid #56d5bb',
//       borderLeft: row.mid_row && '1px solid #56d5bb',
//       borderRight: row.mid_row && '1px solid #56d5bb'
//     })
//   }
// ];
const customStyles = {
  headCells: {
    style: {
      zIndex: "9999px",
      backgroundColor: "#4F46E5",
      color: "white",
      justifyContent: "center",
      fontWeight: "700",
      border: "0.1px solid #dbd7cc",
    },
  },
};
const pageOption = {
  noRowsPerPage: true,
};
const TableComponent = ({
  columns,
  data,
  rowsPerPage,
  progressPending,
  onChangeRowsPerPage,
  paginationPerPage,
  onChangePage,
  paginationTotalRows,
  subHeaderComponent,
  id,
}) => {
  return (
    <DataTable
      id={id}
      pagination
      data={data}
      customTableClass="my-table"
      fixedHeader
      // paginationServer
      columns={columns}
      // noRowsPerPage={false}
      // subHeaderAlign="left"
      // defaultSortFieldId={1}
      // rowsPerPageOptions={100}
      // rowsPerPage={rowsPerPage}
      customStyles={customStyles}
      onChangePage={onChangePage}
      fixedHeaderScrollHeight="85vh"
      progressPending={progressPending}
      paginationPerPage={paginationPerPage}
      highlightOnHover
      subHeaderComponent={subHeaderComponent}
        paginationComponentOptions={pageOption}
      paginationTotalRows={paginationTotalRows}
      onChangeRowsPerPage={onChangeRowsPerPage}
      // conditionalRowStyles={conditionalRowStyles}
    />
  );
};

export default TableComponent;
