import React from "react";
import { Fade } from "react-reveal";
import Select from "react-select";
const SelectInput = ({
  onInputChange,
  numberOptions,
  label,
  DefaultOption,
  onChange,
  value,
  error,
  BelowLabel,
  id,
}) => {
  const options = numberOptions?.map((i) => ({
    ...i,
    label: i.props.value,
    value: i.props.value,
    id: i.id,
  }));
  return (
    <div className="flex flex-col gap-1.5">
      <label
        htmlFor="numberDropdown"
        className="text-[#0D182A] font-medium text-sm leading-7 tracking-[0.32px]"
      >
        {label}
      </label>
      <Select
        id={id}
        placeholder={DefaultOption}
        onChange={onChange}
        onInputChange={onInputChange}
        components={{
          IndicatorSeparator: () => null,
        }}
        defaultInputValue={value || ""}
        isSearchable
        options={options}
        getOptionLabel={(label) => label}
        styles={{
          control: (base, state) => ({
            ...base,
            minHeight: "8px",
            margin: "0",
            padding: "0px !important",
            outline: "none",
            fontSize: "12px",
            borderTop: "0px solid #D0D5DD",
            borderRight: "0px solid #D0D5DD",
            borderLeft: "0px solid #D0D5DD",
            borderBottom: "1px solid #D0D5DD",
            borderRadius: "0px",
            "::placeholder": {
              fontSize: "12px",
              padding: "0px !important",
            },
            ":focus": {
              borderColor: "black",
            },
            boxShadow: "0 !important",
            "&:hover": {
              border: "0 !important",
              borderBottom: "1px solid #D0D5DD",
            },
            backgroundColor: state.isFocused ? "transparent" : "transparent",
            indicatorsContainer: (prevStyle, state) =>
              state.isMulti
                ? {
                    ...prevStyle,
                  }
                : null,
          }),
          singleValue: (provided) => ({
            ...provided,
            color: "#8D8D99",
            fontSize: "12px",
          }),
        }}
      >
        <option value="">{DefaultOption}</option>
        {numberOptions}
      </Select>
      {error && (
        <Fade duration={900}>
          <div className="text-xs text-red-500 duration-1000 transition-all">
            {error}
          </div>
        </Fade>
      )}
      <div className="text-secondary text-xs font-normal leading-[23px] tracking-[0.28px]">
        {BelowLabel}
      </div>
    </div>
  );
};

export default SelectInput;
