import React, { useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import { getUserCredits } from "../services/auth-services";
import { useEffect } from "react";
import TableComponent from "../components/DataTable";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { convertToDollars } from "../utils/helpers";
import { toast } from "react-toastify";

const DashboardIndex = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const handleUserCredit = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    getUserCredits(user.access)
      .then((res) => {
        setData(res?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          const errors = error.response.data;
          Object.keys(errors).forEach((key) => {
            if (Array.isArray(errors[key])) {
              errors[key].forEach((message) => {
                toast.error(`${message}`);
              });
            } else {
              toast.error(`${errors[key]}`);
            }
          });
        } else {
          toast.error("Network error.");
        }
      });
  };
  useEffect(() => {
    handleUserCredit();
  }, []);

  const columns = [
    {
      name: "Date Created",
      selector: (row) => new Date(row?.date_created).toLocaleString(),
      style: {
        justifyContent: "center",
      },
    },
    {
      name: "Credits",
      selector: (row) => row?.credits,
      style: {
        justifyContent: "center",
      },
    },
    {
      name: "Amount",
      selector: (row) =>
        row.amount?.amount_total
          ? convertToDollars(row.amount?.amount_total)
          : 0,
      style: {
        justifyContent: "center",
      },
    },
  ];
  return (
    <DashboardLayout footer>
      <img src="/images/history.png" alt="" className="mx-auto mt-5" />
      <div className="text-3xl mt-6 md:mt-5 text-center text-primary font-medium">
        Credits History
      </div>
      <div className="text-sm text-secondary px-5 py-5 text-justify md:text-center md:w-1/2 mx-auto">
        You can keep track of your credit-related activities, including the
        number of credits you have and the monetary value associated with your
        transactions.
      </div>
      <div className="px-5 lg:mx-72">
        {loading ? (
          <Skeleton className="h-[10vh]" count={4} />
        ) : (
          <TableComponent {...{ data, columns }} />
        )}
      </div>
    </DashboardLayout>
  );
};

export default DashboardIndex;
