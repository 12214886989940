const Button = ({
	bg,
	color,
	textDir,
	fontSize,
	fontWeight,
	onClick,
	text,
	padding,
	type,
	disabledClass,
	disabled,
	customClass,
	children,
	id
}) => {
	return (
		<button
			type={type}
			onClick={onClick}
			id={id}
			disabled={disabled}
			className={`h-full blocks flex items-center justify-center gap-3 text-[#fff] text-base bg-primary font-medium leading-[24px] !w-fit px-6 py-2 rounded-md  ${padding} ${bg} ${color} ${textDir} ${fontSize} ${fontWeight} ${disabledClass} ${customClass}`}
		>
			{children}{text}
		</button>
	);
};

export default Button;
