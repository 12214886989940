export const googleSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    preserveAspectRatio="xMidYMid"
    viewBox="0 0 256 262"
    id="google"
  >
    <path
      fill="#4285F4"
      d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
    ></path>
    <path
      fill="#34A853"
      d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
    ></path>
    <path
      fill="#FBBC05"
      d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
    ></path>
    <path
      fill="#EB4335"
      d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
    ></path>
  </svg>
);

export const IdealSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="30"
    viewBox="0 0 26 30"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.333336 5.66658C0.333336 4.59606 0.916659 3.73583 1.62037 3.11193C2.32563 2.48664 3.27008 1.98445 4.3227 1.58972C6.43777 0.796567 9.27026 0.333252 12.3333 0.333252C15.3964 0.333252 18.2289 0.796567 20.344 1.58972C21.3966 1.98445 22.341 2.48664 23.0463 3.11193C23.75 3.73583 24.3333 4.59606 24.3333 5.66658V12.3168C24.3333 12.7299 24.3113 13.0838 24.2439 13.3976C24.1909 13.6443 24.1644 13.7676 24.0386 13.8222C23.9127 13.8767 23.7792 13.7965 23.5122 13.636C22.9199 13.2799 22.1887 12.9999 21.3334 12.9999C19.5475 12.9999 18.3024 14.221 17.7344 14.8047C17.1412 15.4144 16.5682 16.1442 16.1845 16.6335C16.0278 16.8332 15.8733 17.0335 15.7298 17.2422C15.6566 17.3487 15.62 17.402 15.5709 17.4317C15.5218 17.4614 15.4621 17.4687 15.3428 17.4831C14.3352 17.6049 13.3209 17.6666 12.3333 17.6666C9.1484 17.6666 5.68472 17.025 3.06773 15.7935C2.53038 15.5406 2.04434 15.2715 1.61075 14.9875C0.537824 14.2848 0.333336 13.5765 0.333336 12.3168V5.66658ZM5.09751 7.18452C4.3051 6.87259 3.74032 6.53697 3.38945 6.22589C2.91388 5.80424 2.91388 5.52893 3.38945 5.10728C3.76372 4.77546 4.38136 4.41572 5.25903 4.0866C7.00453 3.43203 9.50537 2.99992 12.3333 2.99992C15.1613 2.99992 17.6621 3.43203 19.4076 4.0866C20.2853 4.41572 20.903 4.77546 21.2772 5.10728C21.7528 5.52893 21.7528 5.80424 21.2772 6.22589C20.903 6.55771 20.2853 6.91745 19.4076 7.24657C17.6621 7.90114 15.1613 8.33325 12.3333 8.33325C11.1372 8.33325 9.99958 8.25595 8.95289 8.1172C7.65042 7.94455 6.36363 7.68292 5.09751 7.18452ZM5.95449 12.4982C5.42559 12.3393 4.86794 12.6391 4.70894 13.168C4.54995 13.6969 4.84982 14.2546 5.37872 14.4136C6.23154 14.6699 7.17515 14.8781 8.1832 15.0311C8.72923 15.114 9.23906 14.7386 9.32195 14.1925C9.40483 13.6465 9.02938 13.1367 8.48335 13.0538C7.55629 12.9131 6.70589 12.7241 5.95449 12.4982Z"
      fill="#453EC9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2133 20.6475C15.1289 20.4203 15.0866 20.3067 15.0109 20.2586C14.9352 20.2105 14.8262 20.2195 14.6081 20.2376C13.84 20.3014 13.0778 20.333 12.3333 20.333C8.79467 20.333 5.14575 19.7183 1.93227 18.2061C1.67472 18.0849 1.42016 17.9568 1.16996 17.8218C0.785598 17.6143 0.593418 17.5106 0.463377 17.5881C0.333336 17.6657 0.333336 17.8778 0.333336 18.3019V24.333C0.333336 25.4035 0.916659 26.2637 1.62037 26.8877C2.32563 27.5129 3.27008 28.0151 4.3227 28.4099C6.43777 29.203 9.27026 29.6663 12.3333 29.6663C14.3199 29.6663 16.2095 29.4714 17.8694 29.1181C18.1935 29.0491 18.3556 29.0146 18.4139 28.9045C18.4722 28.7943 18.3993 28.6155 18.2534 28.2578C18.0951 27.8697 18.0079 27.445 18.0079 26.9999L18.0079 23.6019C18.0079 23.2962 18.0079 23.1434 17.9349 23.0398C17.8619 22.9361 17.6933 22.8758 17.3561 22.7553C16.7149 22.526 15.8672 22.034 15.3522 20.9722C15.2992 20.863 15.2531 20.7546 15.2133 20.6475ZM5.95449 21.8316C5.42559 21.6726 4.86794 21.9725 4.70894 22.5014C4.54995 23.0303 4.84982 23.5879 5.37872 23.7469C6.23154 24.0033 7.17515 24.2115 8.1832 24.3645C8.72923 24.4474 9.23906 24.0719 9.32195 23.5259C9.40483 22.9798 9.02938 22.47 8.48335 22.3871C7.55629 22.2464 6.70589 22.0575 5.95449 21.8316Z"
      fill="#453EC9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.6745 26.9999C22.6745 27.7363 22.0775 28.3333 21.3411 28.3333C20.6048 28.3333 20.0078 27.7363 20.0078 26.9999L20.0078 20.9999L19.2155 20.9999C18.9617 21.0001 18.7063 21.0018 18.454 20.9703C18.2854 20.9493 17.5174 20.8535 17.1516 20.0993C16.785 19.3436 17.1875 18.6767 17.2746 18.5325C17.4166 18.2972 17.5892 18.0832 17.7585 17.8675C18.1513 17.3664 18.6606 16.7208 19.1678 16.1994C19.743 15.6083 20.4617 14.9999 21.3333 14.9999C22.205 14.9999 22.9237 15.6083 23.4988 16.1994C24.0061 16.7208 24.5154 17.3664 24.9082 17.8675C25.0775 18.0832 25.25 18.2972 25.392 18.5325C25.4791 18.6767 25.8817 19.3436 25.5151 20.0993C25.2653 20.6142 24.7604 20.9019 24.2082 20.9708C23.9729 21.0003 23.6854 21.0001 23.4512 20.9999L22.6745 20.9999L22.6745 26.9999Z"
      fill="#453EC9"
    />
  </svg>
);
