import React, { useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import { getUserReports } from "../services/auth-services";
import { useEffect } from "react";
import TableComponent from "../components/DataTable";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Button from "../components/buttons/DefaultButton";
import { Link, useNavigate } from "react-router-dom";
import { TbReportSearch } from "react-icons/tb";

const Reports = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const handleUserCredit = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    getUserReports(user.access)
      .then((res) => {
        setData(res?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleUserCredit();
  }, []);

  const columns = [
    {
      name: "Name",
      selector: (row) => row?.person,
      style: {
        justifyContent: "center",
      },
    },
    {
      name: "Date",
      selector: (row) => new Date(row?.created_at).toLocaleString(),
      style: {
        justifyContent: "center",
      },
    },
    {
      name: "Action",
      selector: (row) => (
        <Link to={`/report/?uuid=${row.id}`}>
          <TbReportSearch className="stroke-primary" size={20} />
        </Link>
      ),
      style: {
        justifyContent: "center",
      },
    },
  ];
  return (
    <DashboardLayout footer>
      <div className="px-5 lg:mx-72 mt-5">
        <div className="flex justify-between mx-auto">
          <div className="text-3xl mt-6 md:mt-5 text-center text-primary font-medium">
            Reports History
          </div>
          <div className="my-5 mx-aut w-fit">
            <Button
              onClick={() => navigate("/create-report")}
              customClass={"text-xs"}
              text={"Create Report"}
            />
          </div>
        </div>
        { }
        {loading ? (
          <Skeleton className="h-[10vh]" count={4} />
        ) : (
          data && <TableComponent {...{ data, columns }} />
        )}
      </div>
    </DashboardLayout>
  );
};

export default Reports;
