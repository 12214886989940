import React from "react";

const Card = ({
  head,
  description = true,
  children,
  cardHeight,
  descriptionCss,
  label,
  blur,
}) => {
  return (
    <div
      className={`car w-full !backdrop-blur-xl bl ${
        blur && "blur"
      } max-h-full pt-6 ${cardHeight}`}

    >
      <div className=" flex items-center  gap-3">
        <div>
          {label && (
            <div className="text-[#455571] text-sm leading-[18.2px]">
              {label}
            </div>
          )}
          <div className="text-lg text-[#0D182A] font-semibold leading-[26px]">
            {head}
          </div>
        </div>
      </div>
      <div
        className={`!text-[15px] leading-[22.2px] font-normal text-[#455571] ${
          !description && "pb-5"
        }`}
      >
        {description && (
          <div
            className={`!text-[15px] leading-[22.2px] font-normal text-[#455571] ${descriptionCss}`}
          >
            {description}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default Card;
