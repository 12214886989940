import { HiOutlineLogin } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { RiHandCoinLine } from "react-icons/ri";

const ProfileSidebar = ({ data }) => {
  const navigation = useNavigate();
  return (
    <div className="main-div-sidebar !w-64">
      <div className="relative flex justify-center py-5">
        <img
          src={"/images/profile.png"}
          className="rounded-full border h-32 w-32"
          alt=""
        />
      </div>
      <div className="text-center">
        <p className="leading-5 font-semibold text-[#272B41] text-base">
          {data.first_name
            ? data?.first_name + " " + data?.last_name
            : "Your Name"}
        </p>
      </div>
      <hr className="my-4" />
      <div
        onClick={() => navigation('/credits')}
        className="flex items-center gap-2 mx-auto w-full text-sm py-2 cursor-pointer"
      >
        <div className="side-bar-icon-sel">
          <RiHandCoinLine size={20} />
        </div>
        <p className="icon-para-sel !block">Credits</p>
      </div>
      <div
        onClick={() => {
          localStorage.removeItem("user");
          window.location.href = "/";
        }}
        className="flex items-center gap-2 mx-auto w-full text-sm py-2 cursor-pointer"
      >
        <div className="side-bar-icon-sel">
          <HiOutlineLogin size={20} />
        </div>
        <p className="icon-para-sel !block">Logout</p>
      </div>
    </div>
  );
};

export default ProfileSidebar;
