import React from "react";

const Loading = ({ currentPercentage, loading,text }) => {
  return (
    <div
      className="backdrop-blur-sm loadings"
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        top: "0px",
        left: "0px",
        display: loading ? "block" : "none",
        zIndex: 100,
        backgroundColor: "	rgb(0, 0, 0,0.5)",
      }}
    >
      <div
        className="loading-center"
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div id="colorWrapper" className="gemBlue">
          <div className="grid">
            <div className="cube">
              <div className="lifter">
                <div className="cube__face"></div>
                <div className="cube__face"></div>
                <div className="cube__face"></div>
              </div>
            </div>
            <div className="cube">
              <div className="lifter">
                <div className="cube__face"></div>
                <div className="cube__face"></div>
                <div className="cube__face"></div>
              </div>
            </div>
            <div className="cube">
              <div className="lifter">
                <div className="cube__face"></div>
                <div className="cube__face"></div>
                <div className="cube__face"></div>
              </div>
            </div>
            <div className="cube">
              <div className="lifter">
                <div className="cube__face"></div>
                <div className="cube__face"></div>
                <div className="cube__face"></div>
              </div>
            </div>
            <div className="cube">
              <div className="lifter">
                <div className="cube__face"></div>
                <div className="cube__face"></div>
                <div className="cube__face"></div>
              </div>
            </div>
            <div className="cube">
              <div className="lifter">
                <div className="cube__face"></div>
                <div className="cube__face"></div>
                <div className="cube__face"></div>
              </div>
            </div>
            <div className="cube">
              <div className="lifter">
                <div className="cube__face"></div>
                <div className="cube__face"></div>
                <div className="cube__face"></div>
              </div>
            </div>
            <div className="cube">
              <div className="lifter">
                <div className="cube__face"></div>
                <div className="cube__face"></div>
                <div className="cube__face"></div>
              </div>
            </div>
            <div className="cube">
              <div className="lifter">
                <div className="cube__face"></div>
                <div className="cube__face"></div>
                <div className="cube__face"></div>
              </div>
            </div>
            <div className="cube">
              <div className="lifter">
                <div className="cube__face"></div>
                <div className="cube__face"></div>
                <div className="cube__face"></div>
              </div>
            </div>
            <div className="cube">
              <div className="lifter">
                <div className="cube__face"></div>
                <div className="cube__face"></div>
                <div className="cube__face"></div>
              </div>
            </div>
            <div className="cube">
              <div className="lifter">
                <div className="cube__face"></div>
                <div className="cube__face"></div>
                <div className="cube__face"></div>
              </div>
            </div>
            <div className="cube">
              <div className="lifter">
                <div className="cube__face"></div>
                <div className="cube__face"></div>
                <div className="cube__face"></div>
              </div>
            </div>
            <div className="cube">
              <div className="lifter">
                <div className="cube__face"></div>
                <div className="cube__face"></div>
                <div className="cube__face"></div>
              </div>
            </div>
            <div className="cube">
              <div className="lifter">
                <div className="cube__face"></div>
                <div className="cube__face"></div>
                <div className="cube__face"></div>
              </div>
            </div>
            <div className="cube">
              <div className="lifter">
                <div className="cube__face"></div>
                <div className="cube__face"></div>
                <div className="cube__face"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="shadow flatShadow dropShadow"> </div>
        <div id="progressBarContainer">
          <div id="progressBar"></div>
        </div>
        <div id="loadingText">
          {text ? text : "Preparing your customized career snapshot"} . . .{" "}
          {currentPercentage.toFixed(0)}%
        </div>
      </div>
    </div>
  );
};

export default Loading;
