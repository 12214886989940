import { jwtDecode } from "jwt-decode";

export function regexEmail(value) {
  const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
  const testRegex = regEx.test(value);
  return testRegex;
}

export const isAuthenticated = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user || !user.refresh) {
    return false; // user is not authenticated
  }
  try {
    const decodedRefreshToken = jwtDecode(user.access);
    const expirationTime = decodedRefreshToken.exp * 1000; // Convert expiration time to milliseconds
    const currentTime = Date.now();
    if (currentTime >= expirationTime) {
      localStorage.clear();
      window.location.href = "/signin";
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const logout = () => {
  localStorage.clear();
  window.location.href = "/signin";
};

export const convertToDollars = (value) => {
  const cents = value;
  const dollars = cents / 100;
  return dollars;
};

export function getFavicon(url) {
  try {
    const urlObj = new URL(url);
    const hostname = urlObj.hostname;
    const parts = hostname.split(".");
    const topLevelDomain = parts.slice(-2).join(".");
    return `https://www.${topLevelDomain}/favicon.ico`;
  } catch (error) {
    return "WebIcon";
  }
}
