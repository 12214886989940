import React, { useEffect, useState } from "react";
import Logo from "../components/logos/Logo";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { isAuthenticated, logout } from "../utils/helpers";
import { BsCurrencyExchange } from "react-icons/bs";
import { FaHome } from "react-icons/fa";
import { Fade } from "react-reveal";
import {
  getUserTotalCredits,
  submit_feedback,
} from "../services/auth-services";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FcSettings } from "react-icons/fc";
import TextInput from "../components/inputs/TextInput";
import Button from "../components/buttons/DefaultButton";
import { TbReportSearch } from "react-icons/tb";
import { toast } from "react-toastify";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("user"));
  const [totalCredits, setTotalCredits] = useState(0);
  const [isFeedback, setIsFeedBack] = useState(false);
  const [feedbackData, setFeedbackData] = useState({
    rating: 1,
    feedback_message: "",
  });
  const [loading, setLoading] = useState(false);
  const sideLinks = [
    {
      text: "Dashboard",
      link: "/",
    },
    {
      text: "Plans",
      link: "/plans",
    },
    {
      text: "Sample Reports",
      link: "/sample-reports",
    },
  ];
  const [settingPopup, setSettingPopup] = useState(false);
  const pathCheck =
    location.pathname !== "/signin" &&
    location.pathname !== "/signup" &&
    location.pathname !== "/confirm_password/" &&
    location.pathname !== "/reset-password";

  const handleUserCredit = () => {
    if (user?.access) {
      getUserTotalCredits(user?.access)
        .then((res) => {
          setTotalCredits(res.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    handleUserCredit();
    if (location?.search) {
      setTimeout(() => {
        setIsFeedBack(true);
      }, 5000);
    }
    // eslint-disable-next-line
  }, []);
  const handleFeedBack = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    submit_feedback(user?.access, feedbackData)
      .then((response) => {
        toast.success("Feedback submitted");
        setFeedbackData({ ...feedbackData, rating: "", feedback_message: "" });
        setIsFeedBack(false);
      })
      .catch((error) => {
        toast.error(error.response.data.feedback_message[0]);
      });
  };
  return (
    <div
      className={`flex !justify-between items-center sticky top-0 !z-50 bg-white mx-auto px-[28px] ${
        isAuthenticated() && "border-b border-borderColor"
      } py-3`}
    >
      <div
        className={`w-full ${
          !!pathCheck ? "!justify-between" : "justify-center"
        }  flex items-center`}
      >
        <Logo onClick={() => navigate("/")} />
        {!!pathCheck && (
          <div className="hidden gap-5 md:flex">
            {sideLinks.map((item, index) => {
              return (
                <Link key={index + "career_plan"} to={item.link}>
                  <div
                    className={`flex border-l-[2px] border-transparent items-center  px-3 py-1 cursor-pointer ${
                      location.pathname === item.link &&
                      "bg-bgHeader !border-l-[2px] rounded-[4px]  !border-primary"
                    }`}
                  >
                    <div>
                      {item.text === "Plans" ? (
                        <BsCurrencyExchange
                          size={20}
                          className={
                            location.pathname === "/plans" && "fill-primary"
                          }
                        />
                      ) : item.text === "Sample Reports" ? (
                        <TbReportSearch
                          size={20}
                          className={
                            location.pathname === "/sample-reports" &&
                            "stroke-primary"
                          }
                        />
                      ) : (
                        <FaHome
                          size={20}
                          className={
                            location.pathname === "/" && "fill-primary"
                          }
                        />
                      )}
                    </div>
                    <div
                      className={`${
                        location.pathname === item.link && "!text-primary"
                      } whitespace-nowrap text-[15px] ml-2.5 leading-6 select-none font-normal text-blackText hover:text-primary`}
                    >
                      {item.text}
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        )}
        {!!pathCheck && (
          <div className={`flex items-center gap-2 relative `}>
            <div className="mr-4"></div>
            <div>
              <div className=" text-primary font-semibold border rounded-md px-3 py-2 text-sm leading-normal flex gap-2 items-center">
                <div className="text-sm text-blackText tracking-wider">
                  {" "}
                  Credits:{" "}
                </div>
                {loading ? (
                  <AiOutlineLoading3Quarters className="animate-spin" />
                ) : (
                  totalCredits?.total_credits
                )}
              </div>
            </div>
            <FcSettings
              size={30}
              className="cursor-pointer"
              onClick={() => setSettingPopup(!settingPopup)}
            />
            {settingPopup && (
              <Fade bottom duration={300}>
                <div
                  onClick={() => setSettingPopup(!settingPopup)}
                  className="fixed h-screen w-screen top-0 left-0 !z-30"
                />
                <div className="bg-white border cursor-pointer !z-40 top-14 absolute w-[150px] overflow-hidden text-white  right-0  rounded-md">
                  <>
                    <div
                      onClick={() => navigate("/")}
                      className="py-2 md:hidden   font-medium text-xs px-2 text-blackText relative"
                    >
                      Dashboard
                    </div>
                    <hr className="md:hidden" />
                    <div
                      onClick={() => navigate("/plans")}
                      className="py-2 md:hidden   font-medium text-xs px-2 text-blackText relative"
                    >
                      Plans
                    </div>
                    <hr className="md:hidden " />
                    <div
                      onClick={() => navigate("/account-settings")}
                      className="py-2 font-medium text-xs px-2 text-blackText relative"
                    >
                      Settings
                    </div>
                    <hr />
                    <div
                      onClick={() => navigate("/credits")}
                      className="py-2 font-medium text-xs px-2 text-blackText relative"
                    >
                      Credits History
                    </div>
                    <hr className=" " />
                  </>

                  <div
                    onClick={logout}
                    className="pb-2 pt-1 font-medium cursor-pointer text-xs px-2 text-blackText relative"
                  >
                    Log Out
                  </div>
                </div>
              </Fade>
            )}
          </div>
        )}
      </div>
      {/* feedback section */}
      {!!pathCheck && (
        <div
          className={`fixed top-[25%] transition-all duration-700 ease-in-out ${
            isFeedback ? "right-[15px]" : "right-[-320px]"
          }  transform translate-y-1/2`}
        >
          <div className="flex  items-center">
            <button
              onClick={() => setIsFeedBack(!isFeedback)}
              className=" transform max-h-fit text-md -rotate-90 rounded-md animate-pule bg-primary -mr-5  text-white px-2 py-1"
            >
              FEEDBACK
            </button>
            <div className="border p-2 rounded-lg bg-white w-full !z-[60]">
              <div className="text-[#0D182A] font-medium text-sm leading-7 tracking-[0.32px]">
                How was your experience?
              </div>
              {/* rating */}
              <div className="flex gap-1">
                {[1, 2, 3, 4, 5].map((num) => {
                  return (
                    <svg
                      key={num}
                      onClick={() =>
                        setFeedbackData({ ...feedbackData, rating: num })
                      }
                      className={`flex cursor-pointer`}
                      fill={`${
                        num <= feedbackData.rating ? "#4F46E5" : "#f0f8ff"
                      }`}
                      width="20px"
                      height="20px"
                      viewBox="0 0 28 28"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        id="Star"
                        d="M13.2822 1.05379C13.5587 0.381959 14.4413 0.381962 14.7178 1.05379L17.6907 8.27671C17.9577 8.92554 18.5501 9.38544 19.2491 9.45327L26.7332 10.1795C27.387 10.243 27.7317 11.1536 27.1721 11.668L21.5253 16.8583C21.0106 17.3315 20.7912 18.0517 20.9386 18.7357L22.5911 26.4075C22.7611 27.1964 21.9722 27.667 21.4346 27.333L14.9719 23.3179C14.374 22.9464 13.626 22.9464 13.0281 23.3179L6.56537 27.333C6.02783 27.667 5.23895 27.1964 5.40888 26.4075L7.06142 18.7357C7.20876 18.0517 6.98944 17.3315 6.47465 16.8583L0.827941 11.668C0.268287 11.1536 0.612997 10.243 1.26684 10.1795L8.75087 9.45327C9.44986 9.38544 10.0423 8.92554 10.3093 8.27671L13.2822 1.05379Z"
                        stroke="#4F46E5"
                        strokeWidth="1.09984"
                      />
                    </svg>
                  );
                })}
              </div>
              {/* message input */}
              <TextInput
                textarea
                value={feedbackData?.feedback_message}
                onChange={(e) =>
                  setFeedbackData({
                    ...feedbackData,
                    feedback_message: e?.target?.value,
                  })
                }
                label={"What can we improve?"}
                customClass={"w-[300px] p-2 rounded-md !border"}
                placeholder={"Enter your feedback"}
              />
              <Button
                text={"Submit"}
                onClick={handleFeedBack}
                customClass={"!mt-3 !px-3 !text-xs !py-2 !mx-auto"}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
