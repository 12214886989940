import { useRef, useEffect } from "react";

function ModelWindow({
  open,
  setOpen,
  children,
  custom,
  customParent,
  option,
}) {
  const modalRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        !modalRef.current.contains(e.target) &&
        e?.target?.id !== "popup" &&
        e?.target?.id.includes("react-select") === false
      ) {
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={`fixed inset-0  z-50 w-screen ${
        open ? "block" : "hidden"
      } ${customParent}`}
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

      <div className="fixed inset-0 mx-auto z-10 w-screen overflow-y-auto">
        <div
          className={`flex  min-h-full ${option} items-end justify-center p-4 text-center sm:items-center sm:p-0`}
        >
          <div
            ref={modalRef}
            className={`relative transform max-h-[90vh] pb-10 overflow-auto rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full ${custom} `}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModelWindow;
