import React from "react";
import DashboardLayout from "../layout/DashboardLayout";

const PrivacyPolicy = () => {
  const MainHeading = ({ text }) => {
    return (
      <div className="text-3xl font-semibold py-3 text-primary ">{text}</div>
    );
  };
  const SmallHeading = ({ text }) => {
    return <div className="text-2xl py-2 text-[#000] font-medium ">{text}</div>;
  };
  const AreaDescription = ({ text }) => {
    return (
      <div className="text-[16px] py-3 text-secondary font-light ">{text}</div>
    );
  };
  const ARRAY = [
    "Personal Identification Information: Name, email address, telephone number, username, and password.",
    "Employment Information: Employment history, educational background, skills, and any other information relevant to your career planning.",
    "Payment Information: Credit card numbers or other payment-related details if you purchase services from us.",
    "Other Information: Preferences, feedback, and any other information you choose to provide us.",
  ];
  return (
    <DashboardLayout footer>
      <div className="w-2/3 mx-auto py-10">
        <MainHeading text={"Privacy Policy"} />
        <SmallHeading text={"Introduction"} />
        <AreaDescription
          text={`Welcome to Career Map. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice or our practices with regards to your personal information, please contact us at admin@creermap.com`}
        />
        <SmallHeading text={"What Information We Collect"} />
        <AreaDescription
          text={`When you visit our website, we collect certain data about you such as IP address, browser type, operating system, the web page you were visiting before accessing our site, the pages of our site which you browsed and the time spent on those pages, items and information searched for on the site, access times and dates, and other statistics.`}
        />
        <SmallHeading text={"How We Use Your Information"} />
        <AreaDescription
          text={`The data we collect will be used to improve our website and services. We may use your data to customize the website according to your interests, to process transactions, for internal record keeping, and promotional emails about new products, special offers or other information which we think you may find interesting.`}
        />
        <SmallHeading text={"Data Sharing"} />
        <AreaDescription
          text={`We do not sell, trade, or rent Users personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above.`}
        />
        <SmallHeading text={"Cookies"} />
        <AreaDescription
          text={`Our website uses "Privacy Policy for trycareermap.com`}
        />
        <AreaDescription
          text={`Welcome to trycareermap.com. We are committed to protecting your personal information and your right to privacy. This Privacy Policy outlines our policies on the collection, use, and disclosure of your information when you use our service and tells you about your privacy rights and how the law protects you.
          `}
        />
        <SmallHeading text={"Information We Collect"} />

        <AreaDescription
          text={`We collect information that you provide to us directly, such as when you create an account, interact with our services, or contact us for support. This information may include, but is not limited to:          `}
        />
        {ARRAY.map((item, index) => {
          return (
            <li key={index} className="text-sm text-secondary">
              {item}
            </li>
          );
        })}
      </div>
    </DashboardLayout>
  );
};

export default PrivacyPolicy;
