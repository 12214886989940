import React from "react";
import DashboardLayout from "../layout/DashboardLayout";

const Success = () => {
  return (
    <DashboardLayout footer>
      <img src="/images/thanku.png" alt="" className="mx-auto mt-[15%]" />
      <div className="text-center text-3xl text-secondary mt-5 font-medium">
        Thank you for purchasing credits!
      </div>
    </DashboardLayout>
  );
};

export default Success;
