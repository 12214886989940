import React from "react";

const FromProgress = ({ isProgress, steps, setIsProgress }) => {
  return (
    <>
      <div className="relative w-fi mx-auto mb-10">
        <div className="flex flex-col justify-between gap-[70px] relative">
          {steps.map((item, index) => (
            <>
              <div
                key={index + item}
                className="bg-[#D0D5DD] w-[1px] h-full left-4 absolute flex"
              />
              <div
                // onClick={() => setIsProgress(index + 1)}
                className="flex items-center gap-3 h-[30px]"
              >
                <div
                  className={`border z-20 ${
                    index + 1 < isProgress
                      ? "bg-[#ECFDF3] border-[#12B76A]"
                      : "border-primary"
                  } bg-[#E1E1E6] text-[#0D182A] font-medium leading-3 flex items-center justify-center transition-all  duration-200 rounded-full min-w-[32px] min-h-[32px] text-sm  ${
                    index + 1 === isProgress && "!bg-primar"
                  } `}
                >
                  {index + 1 === isProgress ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 32 33"
                      fill="none"
                    >
                      <circle
                        cx="16"
                        cy="16.5"
                        r="5"
                        transform="rotate(90 16 16.5)"
                        fill="#453EC9"
                      />
                    </svg>
                  ) : index + 1 < isProgress ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="11"
                      viewBox="0 0 14 11"
                      fill="none"
                    >
                      <path
                        d="M1.16666 7.16675L4.08332 10.0834L12.8333 0.916748"
                        stroke="#12B76A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    index + 1
                  )}
                </div>

                <div
                  className={`${
                    index + 1 === isProgress && "!text-primary"
                  } text-[#0D182A] whitespace-nowrap font-medium flex flex-col text-sm leading-6`}
                >
                  {item}
                  <span
                    className={`text-[#455571] text-xs font-normal leading-normal tracking-[0.28px] ${
                      index + 1 === isProgress && "!text-primary"
                    }`}
                  >
                    Section {index + 1}
                  </span>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default FromProgress;
